import React from 'react'
import { Helmet } from 'react-helmet-async'
import { URL_PRODUCTION } from 'src/constants/values'

const Meta = () => {
  const title = 'Digital Marketplace by Adigital'

  const ogImageSrc =
    'https://ads-marketplace-bucket.s3.eu-west-1.amazonaws.com/og-image.png'

  const description =
    'Digital Marketplace es la plataforma para identificar proveedores digitales verificados con los que mejorar la competitividad y productividad de tu empresa.'

  return (
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
      />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={URL_PRODUCTION} />
      <meta property="og:image" content={ogImageSrc} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:description" content={description} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImageSrc} />

      <title>{title}</title>
    </Helmet>
  )
}

export default Meta
