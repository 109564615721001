import React, { Suspense, useMemo } from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import { marked } from 'marked'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import Carousel from '@gluedigital/react-carousel'
import { usePost, usePosts } from 'src/hooks/api'
import Footer from 'src/components/Footer/Footer'
import Navbar from 'src/components/Navbar/Navbar'
import PostCardsWrapper from 'src/components/PostCards/PostCards'
import BlogSlideHandler from 'src/components/BlogSlides/BlogSlideHandler'
import BlogSlides from 'src/components/BlogSlides/BlogSlides'
import { URL_PRODUCTION } from 'src/constants/values'
import { createSlug } from 'src/utils/slug'
import CompanyInfo from 'src/components/CompanyInfo/CompanyInfo'

import './BlogPost.sass'

declare const __CLIENT__: boolean

const BlogPost = () => {
  const { id, slug } = useParams<{ id: string; slug: string }>()
  const post = usePost(id)

  const {
    account_logo_img,
    account_name,
    category_name,
    name,
    account_id,
    description,
    category_id,
    head_image,
    id: idPost,
    publication_date,
  } = post

  const posts = usePosts()

  const postsCompany = useMemo(
    () =>
      posts.filter(
        (post) => post.account_id === account_id && post.id !== idPost,
      ),
    [posts, account_id, idPost],
  )
  const postsRelated = useMemo(
    () =>
      posts.filter(
        (post) => post.category_id === category_id && post.id !== idPost,
      ),
    [posts, category_id, idPost],
  )

  const canonical = createSlug(post)
  if (`${id}/${slug}` !== canonical) {
    return <Redirect to={`/blog/${canonical}`} />
  }

  const url = URL_PRODUCTION + '/blog/' + id

  const publicationDate = new Date(publication_date).toLocaleString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })

  return (
    <div key={id} id="blog-post" className="page">
      <Navbar logoColor />
      <header
        className="blog-post-heading container"
        style={{ backgroundImage: `url(${head_image})` }}
      />
      <div className="blog-post-separator container">
        <div className="blog-post-main-content">
          <section className="blog-post-info ">
            <h2>
              <Link to={`/category/${category_id}`}>{category_name}</Link>
            </h2>
            <h1>{name}</h1>
            {publication_date && (
              <span className="blog-date-published">
                <FormattedMessage
                  id="blog-posts.date-published"
                  values={{
                    date: publicationDate,
                  }}
                />
              </span>
            )}
            <div>
              <CompanyInfo
                bigSize
                image={account_logo_img}
                id={account_id}
                name={account_name}
              />

              <div className="blog-post-links">
                <a
                  className="icon icon-twitter"
                  href={`https://twitter.com/intent/tweet?text=${name}%20en%20Twitter&url=${url}`}
                  target="_blank"
                  rel="noreferrer"
                />
                <a
                  className="icon icon-facebook"
                  href={`https://www.facebook.com/sharer.php?u=${url}`}
                  target="_blank"
                  rel="noreferrer"
                />
                <a
                  className="icon icon-linkedin"
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
                  target="_blank"
                  rel="noreferrer"
                />
              </div>
            </div>
          </section>
          <section
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: marked.parse(description) }}
          />
        </div>
        {!!postsCompany.length && (
          <aside
            id="blog-posts-container-company"
            className="blog-posts-container"
          >
            <h3>
              <MarkdownMessage
                id="blog-posts.company.text"
                values={{ name: account_name }}
              />
            </h3>
            <div>
              <PostCardsWrapper posts={postsCompany} numberOfItems={4} />
            </div>
          </aside>
        )}
      </div>
      <section
        id="blog-posts-container-related"
        className="blog-posts-container container"
      >
        {__CLIENT__ && !!postsRelated.length && (
          <>
            <h3>
              <FormattedMessage id="blog-posts.related.text" />
            </h3>
            <div className="slides-wrapper">
              <Carousel loop align="start" dots={<BlogSlideHandler />}>
                <BlogSlides slides={postsRelated} />
              </Carousel>
            </div>
          </>
        )}
      </section>
      <Footer />
    </div>
  )
}

const BlogPostWrapper = () => (
  <Suspense>
    <BlogPost />
  </Suspense>
)

export default BlogPostWrapper
