import React, { memo } from 'react'
import { usePages } from 'src/hooks'
import './Pages.sass'

interface PagesProps {
  length: number
}

const Pages = ({ length }: PagesProps) => {
  const { page: actualPage, updatePage } = usePages()

  return (
    <ul id="pages">
      {[...Array(length).keys()].map((elem, index) => (
        <li
          key={elem + 1}
          className={`${actualPage === index ? 'active' : ''}`}
          onClick={() => {
            updatePage(elem)
          }}
        >
          {elem + 1}
        </li>
      ))}
    </ul>
  )
}

export default memo(Pages)
