import React from 'react'
import CompanyInfo from 'src/components/CompanyInfo/CompanyInfo'
import { Post } from 'src/server/types'
import { createSlug } from 'src/utils/slug'
import { useHistory } from 'react-router-dom'

import './BlogHeroBanner.sass'

interface BlogHeroBannerProps {
  post: Post
}

const BlogHeroBanner = ({ post }: BlogHeroBannerProps) => {
  const {
    head_image,
    category_name,
    name,
    account_name,
    account_logo_img,
    account_id,
  } = post
  const navigation = useHistory()

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    const elem = event.target as HTMLElement
    if (!elem.closest('a')) {
      navigation.push(`/blog/${createSlug(post)}`)
    }
  }

  return (
    <header
      style={{ backgroundImage: `url(${head_image})` }}
      id="blog-hero-banner"
      onClick={handleClick}
      role="link"
    >
      <div className="container">
        <div className="header-content">
          <h2>{category_name}</h2>
          <h1>{name}</h1>

          <CompanyInfo
            name={account_name}
            id={account_id}
            bigSize
            image={account_logo_img}
          />
        </div>
      </div>
    </header>
  )
}

export default BlogHeroBanner
