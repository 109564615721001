import React from 'react'
import { Link } from 'react-router-dom'
import CategoriesList from 'src/components/CategoriesList/CategoriesList'
import Footer from 'src/components/Footer/Footer'
import Navbar from 'src/components/Navbar/Navbar'

import './NoPosts.sass'

const NoPosts = () => {
  return (
    <div id="no-posts" className="page">
      <Navbar logoColor />
      <header>
        <div className="container">
          <p>Blog</p>
          <h1>Sin entradas que mostrar</h1>
          <Link to="/blog" className="button">
            <span>Volver al blog</span>
          </Link>
        </div>
      </header>
      <div className="container">
        <section id="blog-categories">
          <CategoriesList />
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default NoPosts
