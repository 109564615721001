import React, { useRef } from 'react'
import Footer from 'src/components/Footer/Footer'
import Navbar from 'src/components/Navbar/Navbar'
import Categories from './InscribeForm/Categories/Categories'
import FooterForm from './InscribeForm/FooterForm/FooterForm'
import General from './InscribeForm/General/General'
import { useInscribeForm } from './inscribeForm'
import Manager from './InscribeForm/Manager/Manager'
import Representative from './InscribeForm/Representative/Representative'
import { FormattedMessage } from 'react-intl'
import Captcha from './InscribeForm/Captcha/Captcha'
import tecnic from '../../static/vectors/tecnic-qualification.svg'
import experience from '../../static/vectors/experience.svg'
import price from '../../static/vectors/inscribe-price.svg'

import './Inscribe.sass'

const Inscribe = () => {
  const steps = [General, Manager, Categories, Representative, Captcha]
  const stepsName = [
    'general',
    'manager',
    'categories',
    'representative',
    'captcha',
  ]
  const {
    handleInscribeForm,
    inscribeForm,
    handleSubmit,
    goPreviousStep,
    setInscribeForm,
    status,
  } = useInscribeForm({ stepsNumber: steps.length })

  const StepComponent = steps[inscribeForm.step || 0]
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div id="inscribe" className="page" ref={ref}>
      <Navbar scrollable={ref} />
      <header id="heading">
        <div className="container">
          <section>
            <h2>
              <FormattedMessage
                id="inscribe.heading.title"
                values={{
                  italic: (
                    <em className="italic">
                      <FormattedMessage id="inscribe.heading.title-italic" />
                    </em>
                  ),
                }}
              />
            </h2>
            <p>
              <FormattedMessage id="inscribe.heading.description" />
            </p>
          </section>
        </div>
      </header>
      <section className="explanation container">
        <h3>
          <FormattedMessage id="inscribe.explanation.title" />
        </h3>
        <p>
          <FormattedMessage id="inscribe.explanation.description" />
        </p>
        <ul className="metodology-list">
          <div className="metodology-list-item">
            <div>
              <span className="icon icon-check" />
            </div>
            <li>
              <FormattedMessage
                id="list.item"
                values={{
                  title: (
                    <strong>
                      <FormattedMessage id="inscribe.explanation.acknowledgment-title" />
                    </strong>
                  ),
                  content: (
                    <FormattedMessage id="inscribe.explanation.acknowledgment-content" />
                  ),
                }}
              />
            </li>
          </div>
          <div className="metodology-list-item">
            <div>
              <span className="icon icon-check" />
            </div>
            <li>
              <FormattedMessage
                id="list.item"
                values={{
                  title: (
                    <strong>
                      <FormattedMessage id="inscribe.explanation.distintion-title" />
                    </strong>
                  ),
                  content: (
                    <FormattedMessage id="inscribe.explanation.distintion-content" />
                  ),
                }}
              />
            </li>
          </div>
          <div className="metodology-list-item">
            <div>
              <span className="icon icon-check" />
            </div>
            <li>
              <FormattedMessage
                id="list.item"
                values={{
                  title: (
                    <strong>
                      <FormattedMessage id="inscribe.explanation.promotion-title" />
                    </strong>
                  ),
                  content: (
                    <FormattedMessage id="inscribe.explanation.promotion-content" />
                  ),
                }}
              />
            </li>
          </div>
          <div className="metodology-list-item">
            <div>
              <span className="icon icon-check" />
            </div>
            <li>
              <FormattedMessage
                id="list.item"
                values={{
                  title: (
                    <strong>
                      <FormattedMessage id="inscribe.explanation.marketing-title" />
                    </strong>
                  ),
                  content: (
                    <FormattedMessage id="inscribe.explanation.marketing-content" />
                  ),
                }}
              />
            </li>
          </div>
          <div className="metodology-list-item">
            <div>
              <span className="icon icon-check" />
            </div>
            <li>
              <FormattedMessage
                id="list.item"
                values={{
                  title: (
                    <strong>
                      <FormattedMessage id="inscribe.explanation.business-title" />
                    </strong>
                  ),
                  content: (
                    <FormattedMessage id="inscribe.explanation.business-content" />
                  ),
                }}
              />
            </li>
          </div>
        </ul>
      </section>
      <section className="metodology container">
        <h3>
          <FormattedMessage id="inscribe.metodology.title" />
        </h3>
        <ul className="metodology-list">
          <div className="metodology-list-item">
            <div>
              <span className="icon icon-check" />
            </div>
            <li>
              <FormattedMessage
                id="list.item"
                values={{
                  title: (
                    <strong>
                      <FormattedMessage id="inscribe.metodology.presentation-title" />
                    </strong>
                  ),
                  content: (
                    <FormattedMessage id="inscribe.metodology.presentation-content" />
                  ),
                }}
              />
            </li>
          </div>
          <div className="metodology-list-item">
            <div>
              <span className="icon icon-check" />
            </div>
            <li>
              <FormattedMessage
                id="list.item"
                values={{
                  title: (
                    <strong>
                      <FormattedMessage id="inscribe.metodology.comprobation-title" />
                    </strong>
                  ),
                  content: (
                    <FormattedMessage id="inscribe.metodology.comprobation-content" />
                  ),
                }}
              />
            </li>
          </div>
          <div className="metodology-list-item">
            <div>
              <span className="icon icon-check" />
            </div>
            <li>
              <FormattedMessage
                id="list.item"
                values={{
                  title: (
                    <strong>
                      <FormattedMessage id="inscribe.metodology.notification-title" />
                    </strong>
                  ),
                  content: (
                    <FormattedMessage id="inscribe.metodology.notification-content" />
                  ),
                }}
              />
            </li>
          </div>
          <div className="metodology-list-item">
            <div>
              <span className="icon icon-check" />
            </div>
            <li>
              <FormattedMessage
                id="list.item"
                values={{
                  title: (
                    <strong>
                      <FormattedMessage id="inscribe.metodology.renovation-title" />
                    </strong>
                  ),
                  content: (
                    <FormattedMessage id="inscribe.metodology.renovation-content" />
                  ),
                }}
              />
            </li>
          </div>
        </ul>
      </section>
      <div className="requirements">
        <section className="container">
          <h3>
            <FormattedMessage id="inscribe.requeriments.title" />
          </h3>
          <p className="requirements-introduction">
            <FormattedMessage id="inscribe.requeriments.description" />
          </p>

          <div className="requirement">
            <img src={tecnic} alt={'tecnic'} />
            <h4>
              <FormattedMessage id="inscribe.requeriments.technical-qualification.title" />
            </h4>
          </div>

          <ul className="requirement-list">
            <li>
              <FormattedMessage id="inscribe.requeriments.technical-qualification.item-1" />
            </li>
            <li>
              <FormattedMessage id="inscribe.requeriments.technical-qualification.item-2" />
            </li>
          </ul>
          <div className="requirement">
            <img src={experience} alt={'experience'} />
            <h4>
              <FormattedMessage id="inscribe.requeriments.experience.title" />
            </h4>
          </div>

          <ul className="requirement-list">
            <li>
              <FormattedMessage id="inscribe.requeriments.experience.item-1" />
            </li>
            <li>
              <FormattedMessage id="inscribe.requeriments.experience.item-2" />
            </li>
            <li>
              <FormattedMessage id="inscribe.requeriments.experience.item-3" />
            </li>
          </ul>
          <div className="requirement">
            <img src={price} alt={'price'} />
            <h4>
              <FormattedMessage id="inscribe.requeriments.price.title" />
            </h4>
          </div>
          <div className="discounts">
            <div className="associateds">
              <span className="associated-type">
                <FormattedMessage id="inscribe.requeriments.asociateds-label" />
              </span>
              <span className="price discount">
                <FormattedMessage
                  id="inscribe.requeriments.price-text"
                  values={{
                    price: (
                      <FormattedMessage
                        id="inscribe.requeriments.price-text.price"
                        values={{ number: 750 }}
                      />
                    ),
                    year: (
                      <FormattedMessage id="inscribe.requeriments.price-text.year" />
                    ),
                    tax: (
                      <span className="iva">
                        <FormattedMessage id="inscribe.requeriments.price-text.tax" />
                      </span>
                    ),
                  }}
                />
              </span>
            </div>
            <div className="associateds">
              <span className="associated-type">
                <FormattedMessage id="inscribe.requeriments.no-asociateds-label" />
              </span>
              <span className="price">
                <FormattedMessage
                  id="inscribe.requeriments.price-text"
                  values={{
                    price: (
                      <FormattedMessage
                        id="inscribe.requeriments.price-text.price"
                        values={{ number: 1500 }}
                      />
                    ),
                    year: (
                      <FormattedMessage id="inscribe.requeriments.price-text.year" />
                    ),
                    tax: (
                      <span className="iva">
                        <FormattedMessage id="inscribe.requeriments.price-text.tax" />
                      </span>
                    ),
                  }}
                />
              </span>
            </div>
          </div>
        </section>
      </div>
      <section className="solicitude container">
        <h3>
          <FormattedMessage id="inscribe.request.title" />
        </h3>
        <p>
          <FormattedMessage id="inscribe.request.description" />
        </p>
        <form id="inscribe-form" onSubmit={handleSubmit}>
          <h4>
            <FormattedMessage
              id="inscribe.inscribe-form.steps"
              values={{
                i: inscribeForm.step + 1,
                d: steps.length,
                title: (
                  <strong>
                    <FormattedMessage
                      id={`inscribe.inscribe-form.step.${
                        stepsName[inscribeForm.step]
                      }`}
                    />
                  </strong>
                ),
              }}
            />
          </h4>
          <StepComponent
            handleInscribeForm={handleInscribeForm}
            inscribeForm={inscribeForm}
            setInscribeForm={setInscribeForm}
            status={status}
          />
          <FooterForm
            status={status}
            inscribeForm={inscribeForm}
            stepsAmount={steps.length}
            goPreviousStep={goPreviousStep}
          />
        </form>
      </section>
      <Footer />
    </div>
  )
}

export default Inscribe
