import { useEffect, useState } from 'react'

type size = 'desktop' | 'mobile'

declare let __CLIENT__: boolean

export const useSize = () => {
  const [size, setSize] = useState<size>(
    __CLIENT__ ? (window.innerWidth < 769 ? 'mobile' : 'desktop') : 'desktop',
  )

  useEffect(() => {
    const handler = () => {
      const newSize: size = window.innerWidth < 769 ? 'mobile' : 'desktop'
      if (newSize !== size) setSize(newSize)
    }
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [size])

  return size
}
