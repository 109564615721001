import { useEffect, useState } from 'react'
import { useQuery } from './query'
import qs from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'

export const usePages = () => {
  const queryParams = useQuery()
  const pageNum = queryParams.get('page')
  const location = useLocation()
  const history = useHistory()

  const [page, setPage] = useState<number>(0)

  const updatePage = (page: number) => {
    setPage(page)
    const queryParams = qs.parse(location.search)
    const newQuery = { ...queryParams, page: page + 1 }
    history.push({ search: qs.stringify(newQuery) })
  }

  useEffect(() => {
    if (pageNum && !isNaN(+pageNum)) {
      const num = +pageNum
      setPage(num - 1)
    } else {
      setPage(0)
    }
  }, [pageNum])

  return {
    page,
    updatePage,
  }
}
