import React, { useRef } from 'react'
// import { Link } from 'react-router-dom'
// import { useRecentCompanies } from 'src/hooks/categories'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import Navbar from 'src/components/Navbar/Navbar'
import Footer from 'src/components/Footer/Footer'
import Searchbar from 'src/components/Searchbar/Searchbar'
// import HomeCompanyCards from './HomeCompanyCards/HomeCompanyCards'
import Categories from 'src/components/Categories/Categories'
import CustomVideo from 'src/components/CustomVideo/CustomVideo'
import { useVideo } from 'src/hooks/video'
import HomePosts from './HomePosts/HomePosts'
import './Home.sass'
import logoCert from 'src/static/images/logo-certified.png'

const Home = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { pauseVideo, play, playVideo, videoRef } = useVideo()

  return (
    <div id="home" className="page" ref={ref}>
      <Navbar scrollable={ref} />
      <div id="what-is">
        <header id="heading">
          <div className="container">
            <h1>
              <FormattedMessage
                id="home.heading.title"
                values={{
                  italic: (
                    <em className="italic">
                      <FormattedMessage id="home.heading.title-italic" />
                    </em>
                  ),
                }}
              />
            </h1>
            <Searchbar />
            <img
              className="logo-certified"
              src={logoCert}
              alt="logo & certification"
            />
          </div>
        </header>
        <section className="container" id="info">
          <h2>
            <FormattedMessage id="home.info.who-we-are-title" />
          </h2>
          <div className="info-container">
            <div className="info-container-description">
              <h3>
                <strong>
                  <FormattedMessage
                    id="home.info.who-we-are-subtitle"
                    values={{
                      italic: (
                        <em className="italic">
                          <FormattedMessage id="home.info.who-we-are-subtitle-italic" />
                        </em>
                      ),
                    }}
                  />
                </strong>
              </h3>
              <p>
                <FormattedMessage id="home.info.parragraph-1" />
              </p>
              <p>
                <FormattedMessage id="home.info.parragraph-2" />
              </p>
            </div>
            <ul className="info-container-values">
              <div className="info-container-values-item">
                <div>
                  <span className="icon icon-check" />
                </div>
                <li>
                  <FormattedMessage
                    id="list.item"
                    values={{
                      title: (
                        <strong>
                          <FormattedMessage id="home.info.capacity-talent-title" />
                        </strong>
                      ),
                      content: (
                        <FormattedMessage id="home.info.capacity-talent-content" />
                      ),
                    }}
                  />
                </li>
              </div>
              <div className="info-container-values-item">
                <div>
                  <span className="icon icon-check" />
                </div>
                <li>
                  <FormattedMessage
                    id="list.item"
                    values={{
                      title: (
                        <strong>
                          <FormattedMessage id="home.info.direct-contact-title" />
                        </strong>
                      ),
                      content: (
                        <FormattedMessage id="home.info.direct-contact-content" />
                      ),
                    }}
                  />
                </li>
              </div>
              <div className="info-container-values-item">
                <div>
                  <span className="icon icon-check" />
                </div>
                <li>
                  <FormattedMessage
                    id="list.item"
                    values={{
                      title: (
                        <strong>
                          <FormattedMessage id="home.info.specialize-title" />
                        </strong>
                      ),
                      content: (
                        <FormattedMessage id="home.info.specialize-content" />
                      ),
                    }}
                  />
                </li>
              </div>
              <div className="info-container-values-item">
                <div>
                  <span className="icon icon-check" />
                </div>
                <li>
                  <FormattedMessage
                    id="list.item"
                    values={{
                      title: (
                        <strong>
                          <FormattedMessage id="home.info.cost-title" />
                        </strong>
                      ),
                      content: <FormattedMessage id="home.info.cost-content" />,
                    }}
                  />
                </li>
              </div>
            </ul>
          </div>
        </section>
        <section className="lime" id="video">
          <div className="container">
            <CustomVideo
              pauseVideo={pauseVideo}
              play={play}
              playVideo={playVideo}
              video={
                'https://ads-marketplace-bucket.s3.eu-west-1.amazonaws.com/DigitalMarketplace-promo-v02.mp4'
              }
              videoRef={videoRef}
            />
            <h3 className="video-text">
              <MarkdownMessage id="home.video.text" />
            </h3>
          </div>
        </section>
        {/* <section className="home-companies container" id="recents">
          <h2 className="home-company-card-title">
            <FormattedMessage
              id="home.recents-provider.title"
              values={{
                italic: (
                  <em className="italic">
                    <FormattedMessage id="home.recents-provider.title-italic" />
                  </em>
                ),
              }}
            />
          </h2>
          <div className="card-container">
            <HomeCompanyCards hook={useRecentCompanies} numberOfItems={3} />
          </div>
        </section> */}
      </div>
      <div>
        <section className="container" id="explore">
          <h2>
            <FormattedMessage id="home.explore.title" />
          </h2>
          <div className="explore-items">
            <Categories />
          </div>
        </section>
      </div>
      <HomePosts />
      <Footer />
    </div>
  )
}

export default Home
