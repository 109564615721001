import React from 'react'

import './SlideHandler.sass'

interface SlideHandlerProps {
  currentSlide?: number
  totalSlides?: number
  slideTo?: (slide: number) => void
}

const SlideHandler = ({ currentSlide, totalSlides, slideTo }: SlideHandlerProps) => {

  return (
    <div className="slide-handler">
      <div className="icon icon-left" onClick={() => slideTo(currentSlide - 1)} />
      {
        [...Array(totalSlides)].map((d, i) => {
          return (
            <div
              onClick={() => slideTo(i)}
              key={i + (d + '')}
              className={`dot ${ i === currentSlide ? 'active' : '' }`} />
          )
        }
      )}
      <div className="icon icon-right" onClick={() => slideTo(currentSlide + 1)} />
    </div>
  )
}

export default SlideHandler
