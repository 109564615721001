import React from 'react'
import { Link } from 'react-router-dom'
import type { Post } from 'src/server/types'
import { createSlug } from 'src/utils/slug'
import CompanyInfo from '../CompanyInfo/CompanyInfo'

import './BlogPostCard.sass'

interface BlogPostCardProps {
  post: Post
  important?: boolean
  showCategoryLink?: boolean
  showCategory?: boolean
}

const BlogPostCard = ({
  post,
  important,
  showCategoryLink,
  showCategory,
}: BlogPostCardProps) => {
  const {
    category_name,
    name,
    account_id,
    account_name,
    head_image,
    account_logo_img,
    category_id,
  } = post

  return (
    <article
      title={name}
      className={`blog-post-card ${important ? 'important' : ''}`}
    >
      <Link
        to={`/blog/${createSlug(post)}`}
        className="blog-post-card-info"
        style={{ backgroundImage: `url(${head_image})` }}
      >
        <div className="post-data">
          {showCategory && <h2>{category_name}</h2>}
          <h1 className="post-title">{name}</h1>
          {account_logo_img && account_name && account_id && (
            <CompanyInfo
              id={account_id}
              name={account_name}
              image={account_logo_img}
              noLink
            />
          )}
        </div>
      </Link>
      {showCategoryLink && (
        <Link to={`/category/${category_id}`} className="post-category">
          {category_name}
        </Link>
      )}
    </article>
  )
}

export default BlogPostCard
