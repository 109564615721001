import React from 'react'
import { useIntl } from 'react-intl'
import TelephoneContact from 'src/components/TelephoneContact/TelephoneContact'
import { InscribeForm, StatusForm } from 'src/server/types'

import './Manager.sass'

interface ManagerProps {
  inscribeForm: InscribeForm
  handleInscribeForm: (event: React.ChangeEvent<HTMLInputElement>) => void
  setInscribeForm: (value: React.SetStateAction<InscribeForm>) => void
  status: StatusForm
}

const Manager = ({
  handleInscribeForm,
  inscribeForm,
  setInscribeForm,
}: ManagerProps) => {
  const intl = useIntl()
  const {
    managerEmail,
    managerName,
    managerSurname,
    managerTelephone,
    managerTelephoneCode,
  } = inscribeForm

  return (
    <div className="inputs">
      <input
        required
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.manager.name',
        })}
        minLength={2}
        maxLength={128}
        name="managerName"
        value={managerName || ''}
        onChange={handleInscribeForm}
      />
      <input
        required
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.manager.surname',
        })}
        minLength={2}
        maxLength={128}
        name="managerSurname"
        value={managerSurname || ''}
        onChange={handleInscribeForm}
      />
      <input
        required
        type="email"
        maxLength={128}
        pattern=".+\.\w+$"
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.manager.email',
        })}
        name="managerEmail"
        value={managerEmail || ''}
        onChange={handleInscribeForm}
      />
      <TelephoneContact
        codeValue={managerTelephoneCode}
        handleCodeValue={(newValue: string) =>
          setInscribeForm((prev) => ({
            ...prev,
            managerTelephoneCode: newValue,
          }))
        }
        inputPlaceholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.manager.telephone',
        })}
        telephoneValue={managerTelephone || ''}
        handleTelephoneValue={handleInscribeForm}
        inputAttrs={{ name: 'managerTelephone' }}
      />
    </div>
  )
}

export default Manager
