import { useEffect, useMemo, useState } from 'react'
import { useCompanies, usePosts } from './api'
import { useQuery } from './query'
import { useSelectCategories } from './categories'
import { search } from 'src/utils/search/search'
//import { useFilter } from './filter'
import { paginate } from 'src/utils/search/paginate'
import { getCompaniesByCategory } from 'src/utils/categories'
import type { CompanyJSON } from 'src/server/types'
import { useSize } from './useSize'

const RESULTS_PER_PAGE_MOBILE = 6
const RESULTS_PER_PAGE_DESKTOP = 24

export const useResults = () => {
  const queryParams = useQuery()
  const query = queryParams.get('query')
  let page = queryParams.get('page')

  if (!page || isNaN(+page)) {
    page = '1'
  }

  const size = useSize()
  const pagesToShow =
    size === 'mobile' ? RESULTS_PER_PAGE_MOBILE : RESULTS_PER_PAGE_DESKTOP
  const companies = useCompanies()
  const posts = usePosts()
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const handleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const closeTooltip = () => {
    setShowTooltip(false)
  }

  const searchResult = useMemo(
    () => search(query, companies, posts),
    [companies, query, posts],
  )

  const [companiesShown, setCompaniesShowed] =
    useState<Array<CompanyJSON>>(searchResult)

  const paginatedCompanies = useMemo(
    () => paginate(companiesShown, pagesToShow),
    [companiesShown, pagesToShow],
  )

  const { handleCategory, categoriesSelected } = useSelectCategories()

  useEffect(() => {
    if (categoriesSelected.length > 0) {
      setCompaniesShowed(
        getCompaniesByCategory(searchResult, categoriesSelected),
      )
    } else {
      setCompaniesShowed(searchResult)
    }
  }, [searchResult, categoriesSelected])

  //const { filter, applyFilter } = useFilter()

  /*useEffect(() => {
    switch (filter) {
      case 'Coincidencia':
        setCompaniesShowed((prevCompanies) => search(query, prevCompanies))
        break
      case 'Mas antiguo':
        setCompaniesShowed((prevCompanies) =>
          [...prevCompanies].sort(
            (a, b) =>
              Number(new Date(a.date_entered)) -
              Number(new Date(b.date_entered)),
          ),
        )
        break
      case 'Mas reciente':
        setCompaniesShowed((prevCompanies) =>
          [...prevCompanies].sort(
            (a, b) =>
              Number(new Date(b.date_entered)) -
              Number(new Date(a.date_entered)),
          ),
        )
        break
    }
  }, [filter, filteredCompanies, query])*/

  return {
    handleCategory,
    categoriesSelected,
    companiesShowed: paginatedCompanies[+page - 1] ?? [],
    query,
    pages: paginatedCompanies.length,
    handleTooltip,
    closeTooltip,
    showTooltip,
  }
}
