import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { processServices } from 'src/utils/company'
import './Tags.sass'

interface TagsContainerProps {
  tags: Array<string>
  maxTags: number
}

const TagsContainer = ({ tags, maxTags }: TagsContainerProps) => {
  const [showAll, setShowAll] = useState<boolean>(false)

  const memoizedTags = useMemo(
    () => processServices(tags, maxTags),
    [tags, maxTags],
  )

  if (showAll || tags.length <= maxTags) {
    return (
      <ul className="tags-container">
        {tags.map((tagName, index) => (
          <li key={index}>
            <Link to={`/results?query=${encodeURIComponent(tagName)}`}>
              {tagName}
            </Link>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <ul className="tags-container">
      {memoizedTags.map((tagName, index) => {
        return index < memoizedTags.length - 1 ? (
          <li key={tagName}>
            <Link to={`/results?query=${encodeURIComponent(tagName)}`}>
              {tagName}
            </Link>
          </li>
        ) : (
          <li
            key={tagName}
            onClick={() => setShowAll(true)}
            className="see-more-tags"
          >
            {tagName}
          </li>
        )
      })}
    </ul>
  )
}

export default TagsContainer
