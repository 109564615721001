import React from 'react'
import './BlogPostCardPlaceholder.sass'

const BlogPostCardPlaceholder = () => {
  return (
    <div className="blog-post-card blog-post-placeholder">
      <div className="category item"></div>
      <div className="title-wrapper">
        <div className="title item"></div>
        <div className="title item"></div>
      </div>

      <div className="company-info">
        <div className="logo item"></div>
        <div className="name item"></div>
      </div>
    </div>
  )
}

export default BlogPostCardPlaceholder
