import React from 'react'
import useConsent from './useConsent'
import { Helmet } from 'react-helmet-async'

const Analytics = () => {
  const consent = useConsent()

  if (!consent.gotConsent) {
    return null
  }

  const scriptTagManager = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PZWT4XC');
  `

  return (
    <Helmet>
      <script>{scriptTagManager}</script>
    </Helmet>
  )
}

export default Analytics
