import React, { Suspense } from 'react'
import { Link } from 'react-router-dom'
import { useCategories } from 'src/hooks/api'

import './Categories.sass'

const Categories = () => {
  const categories = useCategories()
  return (
    <>
      {categories.map(({ category_id, category_name, category_image }) => (
        <Link
          key={category_id}
          to={`/results?query=&category=${encodeURIComponent(
            category_name,
          )}&page=1`}
        >
          <div className="category-card">
            <img src={category_image} alt={category_name} />
            <span>{category_name}</span>
          </div>
        </Link>
      ))}
    </>
  )
}

const CategoriesWrapper = () => (
  <Suspense>
    <Categories />
  </Suspense>
)

export default CategoriesWrapper
