import { useEffect, useMemo, useState } from 'react'
import { useCategories, useCompanies } from './api'
import { useQuery } from './query'
import { useHistory } from 'react-router-dom'

const checkIfCategoryExists = (
  categories: Array<string>,
  param: string,
): string => {
  if (!param) return null
  return categories.find((categoryName) => categoryName === param)
}

export const useSelectCategories = () => {
  const query = useQuery()
  const history = useHistory()
  const categoryParam = query.get('category')
  const result = query.get('query')
  const categories = useCategories()

  useEffect(() => {
    if (!categoryParam) {
      setCategoriesSelected([])
    }
  }, [categoryParam])

  const categoriesNames = useMemo(
    () => categories.map(({ category_name }) => category_name),
    [categories],
  )

  const retrieveFromParam = (): Array<string> => {
    if (!categoryParam) return []
    const splitedCategoriesNames = categoryParam.split(';')
    const validCategories: Array<string> = []

    splitedCategoriesNames.forEach((categoryName) => {
      const category = checkIfCategoryExists(categoriesNames, categoryName)
      if (category) {
        validCategories.push(category)
      }
    })
    return validCategories
  }

  const [categoriesSelected, setCategoriesSelected] = useState<Array<string>>(
    () => retrieveFromParam(),
  )

  const addCategory = (newCategory: string) => {
    setCategoriesSelected((prevCategories) => {
      const newState = [...prevCategories, newCategory]
      updateQueryParams(newState)
      return newState
    })
  }

  const removeCategory = (categoryToRemove: string) => {
    setCategoriesSelected((prevCategories) => {
      const newState = prevCategories.filter(
        (categoryName) => categoryToRemove !== categoryName,
      )
      updateQueryParams(newState)
      return newState
    })
  }

  const handleCategory = {
    add: addCategory,
    remove: removeCategory,
  }

  const updateQueryParams = (categories: Array<string>) => {
    const urlParams = new URLSearchParams()

    urlParams.set('query', result)
    urlParams.set('category', categories.sort().join(';'))
    urlParams.set('page', '1')
    history.replace({
      search: urlParams.toString(),
    })
  }

  return {
    categoriesSelected,
    handleCategory,
  }
}

export const useRecentCompanies = () => {
  const companies = useCompanies()
  return useMemo(
    () =>
      companies
        .slice(0, 5)
        .sort(
          (a, b) =>
            new Date(a.date_entered).getTime() -
            new Date(b.date_entered).getTime(),
        ),
    [companies],
  )
}
