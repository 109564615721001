import { useMemo } from 'react'
import { ItemAutocomplete } from 'src/server/types'
import { createSlug } from 'src/utils/slug'
import { useCategories, useCompanies } from './api'

const score = (element: string, value: string) => {
  if (element.startsWith(value)) return 10
  if (element.includes(value)) return 5
  return 0
}

const sortScore = (a: ItemAutocomplete, b: ItemAutocomplete) =>
  b.score - a.score || b.label.localeCompare(a.label)

const removeNoScore = (element: ItemAutocomplete) => element.score !== 0

export const useAutocomplete = (value: string) => {
  const categories = useCategories()
  const companies = useCompanies()
  const tagsNames = useMemo(
    () =>
      categories
        .map(({ tags }) => tags)
        .flat()
        .map(({ tag_name }) => tag_name),
    [categories],
  )
  const categoriesNames = useMemo(
    () => categories.map(({ category_name }) => category_name),
    [categories],
  )

  const suggestions: Array<{
    name: string | null
    items: Array<ItemAutocomplete>
  }> = []

  if (value !== '') {
    value = value.toLowerCase()
    const categoriesAndTags = [...categoriesNames, ...tagsNames]

    const categoriesAndTagsUnique = categoriesAndTags.filter(
      (element, index) => categoriesAndTags.indexOf(element) === index,
    )

    const categoriesAndTagsOrdered = categoriesAndTagsUnique
      .map((element) => ({
        label: element,
        score: score(element.toLowerCase(), value),
      }))
      .filter(removeNoScore)
      .slice(0, 7)
      .sort(sortScore)

    const companiesOrdered = companies
      .map((company) => ({
        label: company.name,
        score: score(company.name.toLowerCase(), value),
        url: `/company/${createSlug(company)}`,
      }))
      .filter(removeNoScore)
      .slice(0, 2)
      .sort(sortScore)

    categoriesAndTagsOrdered.length > 0 &&
      suggestions.push({
        name: null,
        items: categoriesAndTagsOrdered,
      })

    companiesOrdered.length > 0 &&
      suggestions.push({
        name: companiesOrdered.length > 0 ? 'Empresas' : null,
        items: companiesOrdered,
      })
  }

  return suggestions
}
