/**
 * For example, tags: ['UX','UI','React'], index: 2 => ['UX','UI',+1]
 * @param tags Tags to process
 * @param index the limit of tag written
 * @returns An array with the tags to the index, then the rest of elements in a number
 */
export const processServices = (
  tagsNames: Array<string>,
  index: number,
): Array<string> => {
  if (tagsNames.length <= index) {
    return tagsNames
  }
  const servicesReduced = tagsNames.slice(0, index)
  const rest = `+${tagsNames.length - index}`
  return [...servicesReduced, rest]
}
