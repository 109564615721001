import { registerModal, useModal } from '@gluedigital/modal'
import React, { useEffect } from 'react'
import ContactForm from '../ContactForm/ContactForm'

import './ContactModal.sass'

interface ContactModalProps {
  handleChangeModal: () => void
  companyId: string
}

const ContactModal = ({ handleChangeModal, companyId }: ContactModalProps) => {
  const modal = useModal()
  useEffect(() => {
    const closeWithEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        modal.hide()
      }
    }

    document.addEventListener('keydown', closeWithEsc, false)

    return () => {
      document.removeEventListener('keydown', closeWithEsc, false)
    }
  }, [modal])

  return (
    <div className="modal-content contact-modal">
      <header>
        <button
          className="close-modal"
          onClick={() => {
            handleChangeModal()
            modal.hide()
          }}
        >
          <span className="icon icon-cross" />
        </button>
      </header>
      <section>
        <ContactForm companyId={companyId} />
      </section>
    </div>
  )
}

registerModal('contact-modal', ContactModal)
