import React, { Suspense, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Tags from 'src/components/Tags/Tags'
import { useCategories } from 'src/hooks/api'
import type { InscribeForm, StatusForm } from 'src/server/types'

import './Categories.sass'

interface CategoriesProps {
  inscribeForm: InscribeForm
  setInscribeForm: (elem: InscribeForm) => void
  handleInscribeForm: (event: React.ChangeEvent<HTMLInputElement>) => void
  status: StatusForm
}

const Categories = ({ inscribeForm, setInscribeForm }: CategoriesProps) => {
  const categories = useCategories()

  const [categorySelected, setCategorySelected] = useState(categories[0])

  const handleTags = (event: React.ChangeEvent<HTMLInputElement>) => {
    const copyServices = [...inscribeForm.services]
    const { checked, value } = event.target
    const tag_id = event.target.dataset.id
    if (checked) {
      if (
        copyServices
          .map(({ category: { category_id } }) => category_id)
          .includes(categorySelected.category_id)
      ) {
        copyServices
          .find(
            ({ category: { category_id } }) =>
              category_id === categorySelected.category_id,
          )
          .tags.push({
            tag_id: tag_id,
            tag_name: value,
          })
        setInscribeForm({ ...inscribeForm, services: copyServices })
      } else {
        setInscribeForm({
          ...inscribeForm,
          services: [
            ...inscribeForm.services,
            {
              category: {
                category_id: categorySelected.category_id,
                category_name: categorySelected.category_name,
              },
              tags: [{ tag_id: tag_id, tag_name: value }],
            },
          ],
        })
      }
    } else {
      const service = copyServices.find(
        ({ category: { category_id } }) =>
          category_id === categorySelected.category_id,
      )
      service.tags = service.tags.filter(
        ({ tag_id: tagServiceId }) => tagServiceId !== tag_id,
      )
      if (service.tags.length === 0) {
        setInscribeForm({
          ...inscribeForm,
          services: copyServices.filter(
            ({ category: { category_id } }) =>
              category_id !== service.category.category_id,
          ),
        })
      } else {
        setInscribeForm({ ...inscribeForm, services: copyServices })
      }
    }
  }

  const chosenCategories = inscribeForm.services.map(
    ({ category }) => category.category_name,
  )
  const areDisabled = chosenCategories.length >= 2

  return (
    <>
      <p className={areDisabled ? 'advice-categories' : ''}>
        {areDisabled ? (
          <FormattedMessage id="inscribe.inscribe-form.categories.advice-categories.disabled" />
        ) : (
          <FormattedMessage id="inscribe.inscribe-form.categories.advice-categories" />
        )}
      </p>
      <input
        type="checkbox"
        className="input-min-services"
        required
        checked={inscribeForm.services.length > 0}
        onChange={() => null}
      />
      <div id="categories-form" className="container">
        <div id="category-select">
          <div className="categories">
            <h4>
              <FormattedMessage id="inscribe.inscribe-form.categories.categories" />
            </h4>
            {categories.map((category) => (
              <span
                className={`${
                  categorySelected.category_id === category.category_id
                    ? 'open'
                    : ''
                } ${
                  chosenCategories.includes(category.category_name)
                    ? 'selected'
                    : ''
                }`}
                key={category.category_id}
                onClick={() => setCategorySelected(category)}
              >
                {category.category_name}
              </span>
            ))}
          </div>
          <div className="tags">
            <h4>
              <FormattedMessage id="inscribe.inscribe-form.categories.tags" />
            </h4>
            {categorySelected.tags.flat().map((tag) => (
              <label key={tag.tag_id}>
                <input
                  disabled={
                    areDisabled &&
                    !chosenCategories.includes(categorySelected.category_name)
                  }
                  type="checkbox"
                  onChange={handleTags}
                  data-id={tag.tag_id}
                  value={tag.tag_name}
                  checked={inscribeForm.services
                    .map(({ tags }) => tags)
                    .flat()
                    .map((tag) => tag.tag_id)
                    .includes(tag.tag_id)}
                />
                <span>{tag.tag_name}</span>
              </label>
            ))}
          </div>
        </div>
        <div id="category-info">
          <h5>
            <FormattedMessage id="inscribe.inscribe-form.categories.services-selected" />
          </h5>
          <div>
            {inscribeForm.services.map((service) => (
              <div key={service.category.category_id} className="tags">
                <span>{service.category.category_name}</span>
                <Tags
                  tags={service.tags.map((tag) => tag.tag_name)}
                  maxTags={3}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

const CategoriesWrapper = ({
  inscribeForm,
  setInscribeForm,
  handleInscribeForm,
  status,
}: CategoriesProps) => {
  return (
    <Suspense>
      <Categories
        status={status}
        handleInscribeForm={handleInscribeForm}
        inscribeForm={inscribeForm}
        setInscribeForm={setInscribeForm}
      />
    </Suspense>
  )
}

export default CategoriesWrapper
