import React, { Suspense, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from 'src/components/Navbar/Navbar'
import Footer from 'src/components/Footer/Footer'
import CategoriesList from '../../components/CategoriesList/CategoriesList'
import PostGrid from 'src/components/PostGrid/PostGrid'

import './Category.sass'
import { usePosts } from 'src/hooks/api'
import PostHeroBanner from 'src/components/PostHeroBanner/PostHeroBanner'
import NoPosts from '../NoPosts/NoPosts'

const Category = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { id } = useParams<{ id: string }>()
  const posts = usePosts()
  const postsCategory = useMemo(
    () => posts.filter((post) => post?.category_id === id),
    [posts, id],
  )
  if (!postsCategory.length) return <NoPosts />

  const { category_name, category_image } = postsCategory[0]

  return (
    <div id="category" className="page" ref={ref}>
      <Navbar scrollable={ref} />
      <PostHeroBanner
        url={`/results?query=${encodeURIComponent(category_name)}`}
        name={category_name || ''}
        imgSrc={category_image || ''}
      />
      <div className="container">
        <section id="category-list">
          <CategoriesList />
        </section>
        <section id="category-posts">
          <PostGrid posts={postsCategory || []} />
        </section>
      </div>
      <Footer />
    </div>
  )
}

const CategoryWrapper = () => (
  <Suspense>
    <Category />
  </Suspense>
)

export default CategoryWrapper
