import { CONSENT_ACCEPT, CONSENT_REJECT } from 'src/store/actions/consent'

export default (state: boolean, action) => {
  switch (action.type) {
    case CONSENT_ACCEPT:
      return true
    case CONSENT_REJECT:
      return false
    default:
      return state !== undefined ? state : null
  }
}
