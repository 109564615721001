import React from 'react'
import { Link } from 'react-router-dom'

import './PostHeroBanner.sass'

const PostHeroBanner = ({
  imgSrc,
  name,
  url,
}: {
  imgSrc: string
  name: string
  url: string
}) => {
  return (
    <header id="post-hero-banner">
      <div className="container">
        <div>
          {/*TODO Replace with CompanyInfo? */}
          <Link to={url}>
            <div className="image-wrapper">
              <img src={imgSrc} alt={`${name} company logo`} />
            </div>
          </Link>
          <Link to={url}>
            <h1>{name}</h1>
          </Link>
        </div>
      </div>
    </header>
  )
}

export default PostHeroBanner
