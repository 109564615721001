import React, { useState, memo, useEffect, Suspense } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from 'src/hooks'
import { useNavigateArrows } from './navigateArrows'
import { useAutocomplete } from 'src/hooks/autocomplete'

import './Searchbar.sass'

const Searchbar = () => {
  const navigate = useHistory()
  const location = useLocation()
  const intl = useIntl()
  const query = useQuery()
  const search = query.get('query')
  const [value, setValue] = useState<string>('')
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const suggestions = useAutocomplete(value)
  const { extractItem, resetHover, hoveredItem, handleHover } =
    useNavigateArrows(suggestions)

  const handleSubmit = (e) => {
    e.preventDefault()
    handleNavigate(value)
  }

  useEffect(() => {
    search && setValue(search)
  }, [search])

  // Removes the category param from url and hide suggestions list
  const handleNavigate = (item: string) => {
    setShowSuggestions(false)
    const urlParams = new URLSearchParams(location.search)
    if (urlParams.has('category')) {
      urlParams.delete('category')
    }
    urlParams.set('query', item)
    navigate.push({
      pathname: '/results',
      search: urlParams.toString(),
    })
  }

  return (
    <div
      className={`searchbar ${
        showSuggestions && suggestions.length > 0 ? 'suggestions' : ''
      }`}
    >
      <form
        className="searchbar-search"
        onSubmit={handleSubmit}
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            event.preventDefault()
            if (hoveredItem !== -1) {
              const { label, url } = extractItem()
              url ? navigate.push(url) : handleNavigate(label)
            } else handleNavigate(value)
          }
        }}
      >
        <input
          type="search"
          placeholder={intl.formatMessage({ id: 'placeholder.search' })}
          value={value}
          onChange={({ target }) => setValue(target.value)}
          onFocus={() => setShowSuggestions(true)}
          onBlur={() => setShowSuggestions(false)}
        />
        <button>
          <span className="icon icon-lens" />
          <span className="text">
            <FormattedMessage id="search-bar.button.title" />
          </span>
        </button>
      </form>

      {showSuggestions && suggestions.length > 0 && (
        <div className="searchbar-suggestions">
          {suggestions.map(({ name, items }, index) => (
            <div key={index}>
              {name && <h4>{name}</h4>}
              <ul>
                {items.map(({ label, url }) => (
                  <li
                    key={label}
                    onMouseDown={() => {
                      if (url) {
                        navigate.push(url)
                      } else {
                        handleNavigate(label)
                      }
                    }}
                    onMouseEnter={() => {
                      if (hoveredItem !== -1) resetHover()
                      handleHover(label)
                    }}
                    onMouseLeave={resetHover}
                    className={`${
                      extractItem()?.label === label ? 'hovered' : ''
                    }`}
                  >
                    <a href={url}>{label}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const SearchbarFallback = () => {
  const intl = useIntl()
  return (
    <div className={'searchbar'}>
      <form className="searchbar-search">
        <input
          type="search"
          disabled
          placeholder={intl.formatMessage({ id: 'placeholder.search' })}
        />
        <button>
          <span className="icon icon-lens" />
          <span className="text">
            <FormattedMessage id="search-bar.button.title" />
          </span>
        </button>
      </form>
    </div>
  )
}

const SearchbarWrapper = () => {
  return (
    <Suspense fallback={<SearchbarFallback />}>
      <Searchbar />
    </Suspense>
  )
}

export default memo(SearchbarWrapper)
