import React from 'react'
import { useVideo } from 'src/hooks/video'
import { Video } from 'src/server/types'
import CustomVideo from '../../../components/CustomVideo/CustomVideo'


import './CompanyVideo.sass'

interface CompanyVideoProps {
  video: Video
}

const CompanyVideo = ({ video }: CompanyVideoProps) => {
  const { pauseVideo, play, playVideo, videoRef } = useVideo()
  const { title, url } = video
  return (
    <div className="company-video-card">
      <CustomVideo
        video={url}
        pauseVideo={pauseVideo}
        play={play}
        playVideo={playVideo}
        videoRef={videoRef}
      />
      <span className={`video-title ${play ? 'hide' : ''}`}>{title}</span>
    </div>
  )
}

export default CompanyVideo
