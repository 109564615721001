import React from 'react'
import './CategorySelectRow.sass'

interface CategorySelectRowProps {
  option: { value: string }
  checked: boolean
}

const CategorySelectRow = ({
  option: { value },
  checked,
}: CategorySelectRowProps) => {
  return (
    <label className="category-select-row">
      <input
        type="checkbox"
        checked={checked}
        value={value}
        onChange={() => null}
      />
      <span>
        <b>{value}</b>
      </span>
    </label>
  )
}

export default CategorySelectRow
