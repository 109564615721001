import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import CountryRow from 'src/components/CountryRow/CountryRow'
import CustomSelect from 'src/components/CustomSelect/CustomSelect'
import type { InscribeForm, StatusForm } from 'src/server/types'
import countries from '../../../../../misc/world.json'

interface GeneralProps {
  inscribeForm: InscribeForm
  handleInscribeForm: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void
  setInscribeForm: (value: React.SetStateAction<InscribeForm>) => void
  status: StatusForm
}

const General = ({
  inscribeForm,
  handleInscribeForm,
  setInscribeForm,
}: GeneralProps) => {
  const intl = useIntl()

  const {
    companyName,
    socialDenomination,
    nif,
    cp,
    webPage,
    employesNumber,
    country,
  } = inscribeForm

  const selectOptions = useMemo(
    () => countries.map((country) => ({ id: country.id, value: country.name })),
    [],
  )

  const handleCountry = (newValue: string) =>
    setInscribeForm((prev) => ({ ...prev, country: newValue }))

  return (
    <div className="inputs">
      <input
        required
        minLength={2}
        maxLength={128}
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.general.name',
        })}
        name="companyName"
        value={companyName || ''}
        onChange={handleInscribeForm}
      />
      <input
        required
        minLength={2}
        maxLength={128}
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.general.social-denomination',
        })}
        name="socialDenomination"
        value={socialDenomination || ''}
        onChange={handleInscribeForm}
      />
      <input
        required
        minLength={2}
        maxLength={64}
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.general.nif',
        })}
        name="nif"
        value={nif || ''}
        onChange={handleInscribeForm}
      />
      <input
        required
        minLength={2}
        maxLength={32}
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.general.postal-code',
        })}
        name="cp"
        value={cp || ''}
        onChange={handleInscribeForm}
      />
      <CustomSelect
        onChange={{ add: handleCountry }}
        value={country}
        options={selectOptions}
        OptionComponent={CountryRow}
      />
      <input
        required
        type="url"
        maxLength={128}
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.general.web-page',
        })}
        name="webPage"
        value={webPage || ''}
        onChange={handleInscribeForm}
      />
      <input
        required
        type="number"
        min={0}
        max={999999}
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.general.employes',
        })}
        name="employesNumber"
        value={employesNumber || ''}
        onChange={handleInscribeForm}
      />
    </div>
  )
}

export default General
