import React, { Suspense, useRef } from 'react'
import Navbar from 'src/components/Navbar/Navbar'
import Footer from 'src/components/Footer/Footer'
import BlogHeroBannerDefault from './BlogHeroBanner/BlogHeroBannerPlaceholder'
import BlogHeroBanner from './BlogHeroBanner/BlogHeroBanner'
import CategoriesList from '../../components/CategoriesList/CategoriesList'
import { usePaginatedPosts } from 'src/hooks/api'
import PostGrid from 'src/components/PostGrid/PostGrid'
import { sortByField } from 'src/utils/utils'
import { sortScore } from '../../utils/search/search'

import './Blog.sass'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'
import Loading from 'src/components/Loading/Loading'

const Blog = () => {
  const ref = useRef<HTMLDivElement>(null)
  const posts = usePaginatedPosts()
  const refScrollInfinite = useInfiniteScroll(posts.loadMore, 3)

  console.log(posts.isLoadingMore)

  const orderedPosts = [
    ...posts.data
      .filter((post) => post.publication_date !== null)
      .sort(sortByField('publication_date')),
    ...posts.data
      .filter((post) => post.publication_date === null)
      .map((post) => ({ ...post, score: 0 }))
      .sort(sortScore),
  ]

  return (
    <div id="blog" className="page" ref={ref}>
      <Navbar scrollable={ref} />
      <Suspense fallback={<BlogHeroBannerDefault />}>
        <BlogHeroBanner post={orderedPosts[0]} />
      </Suspense>
      <div className="container">
        <section id="blog-categories">
          <CategoriesList />
        </section>
        <section id="blog-posts">
          <PostGrid
            ref={refScrollInfinite}
            posts={orderedPosts}
            isLoadingMore={posts.isLoadingMore}
          />
        </section>
      </div>
      <Footer />
    </div>
  )
}

const BlogWrapper = () => (
  <Suspense fallback={<Loading />}>
    <Blog />
  </Suspense>
)

export default BlogWrapper
