import React from 'react'
import Footer from 'src/components/Footer/Footer'
import Navbar from 'src/components/Navbar/Navbar'

import './NotFound.sass'
import notFound from 'src/static/vectors/not-found.svg'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div id="not-found" className="page">
      <Navbar logoColor />
      <div className="container">
        <img src={notFound} alt="not found image" />
        <h1>Página no encontrada</h1>
        <p>La página que estás buscando no existe</p>
        <Link to="/" className="button">
          <span>Volver</span>
        </Link>
      </div>
      <Footer />
    </div>
  )
}

export default NotFound
