import React, { Suspense, useMemo, useRef } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import Navbar from 'src/components/Navbar/Navbar'
import Footer from 'src/components/Footer/Footer'
import CategoriesList from '../../components/CategoriesList/CategoriesList'
import PostGrid from 'src/components/PostGrid/PostGrid'

import './CompanyPosts.sass'
import { useCompany, usePosts } from 'src/hooks/api'
import PostHeroBanner from 'src/components/PostHeroBanner/PostHeroBanner'
import NoPosts from '../NoPosts/NoPosts'
import { createSlug } from 'src/utils/slug'

const CompanyPosts = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { id, slug } = useParams<{ id: string; slug: string }>()
  const company = useCompany(id)
  const posts = usePosts()
  const postsCompany = useMemo(
    () => posts.filter((post) => post.account_id === company.id),
    [posts, company.id],
  )

  if (!postsCompany.length) return <NoPosts />

  const canonical = createSlug(company)
  if (`${id}/${slug}` !== canonical) {
    return <Redirect to={`/company-posts/${canonical}`} />
  }
  const { account_name, account_logo_img } = postsCompany[0]

  return (
    <div id="company-posts" className="page" ref={ref}>
      <Navbar scrollable={ref} />
      <PostHeroBanner
        url={`/company/${canonical}`}
        name={account_name || ''}
        imgSrc={account_logo_img || ''}
      />
      <div className="container">
        <section id="category-list">
          <CategoriesList />
        </section>
        <section id="company-posts-grid">
          <PostGrid posts={postsCompany || []} />
        </section>
      </div>
      <Footer />
    </div>
  )
}

const CompanyPostsWrapper = () => (
  <Suspense>
    <CompanyPosts />
  </Suspense>
)

export default CompanyPostsWrapper
