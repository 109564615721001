import React from 'react'
import useConsent from './useConsent'
import BannerContent from 'src/routes/Cookies/BannerContent'

import './Banner.sass'

const Banner = () => {
  const { accept, reject, gotAnswer } = useConsent()
  if (gotAnswer) {
    // User already decided, so we don't have to show this anymore
    return null
  }

  const handleAccept = () => accept()

  const handleReject = () => reject()

  return (
    <BannerContent handleAccept={handleAccept} handleReject={handleReject} />
  )
}

export default Banner
