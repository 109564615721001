interface Item {
  id: string
  name: string
}

const slug = (name: string): string =>
  name
    .replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, ' ')
    .toLowerCase()
    .replace(/^\s+|\s+$/gm, '')
    .replace(/\s+/g, '-')

export const createSlug = <T extends Item>({ id, name }: T) => {
  const nameTransformed = slug(name)
  const idTransformed = id.substring(0, 6)
  return `${idTransformed}/${nameTransformed}`
}
