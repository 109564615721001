import React, { Suspense } from 'react'
import { Link } from 'react-router-dom'
import Carousel from '@gluedigital/react-carousel'
import { useCategories } from 'src/hooks/api'
import SlideHandler from '../Slides/SlideHandler'
import Slides from '../Slides/Slides'

import './CategoriesList.sass'

declare const __CLIENT__: boolean

const CategoriesList = () => {
  const categories = useCategories()
  return (
    <ul className="categories-list">
      {__CLIENT__ && !!categories.length && (
        <Carousel loop align="start" dots={<SlideHandler />}>
          <Slides slides={categories.map(({ category_id, category_name, category_image }) => (
            <Link to={`/category/${category_id}`} key={category_id} className="category-card-link">
              <img src={category_image} alt={category_name} />
              <span>{category_name}</span>
            </Link>
          ))}></Slides>
        </Carousel>
      )}
    </ul>
  )
}

const CategoriesListWrapper = () => (
  <Suspense>
    <CategoriesList />
  </Suspense>
)

export default CategoriesListWrapper
