import React, { FormEvent, useState } from 'react'
import { CaptchaResponse, StatusForm } from 'src/server/types'
import { useCaptchaAsync, useCompanyForm } from './api'
import { useField } from './useField'

export const useContactForm = (companyId: string) => {
  const { reset: resetName, ...name } = useField()
  const { reset: resetSurnames, ...surname } = useField()
  const { reset: resetTelephone, ...telephone } = useField()
  const { reset: resetEmail, ...email } = useField()
  const { reset: resetMessage, ...message } = useField()
  const [code, setCode] = useState<string>('+34')
  const [result, setResult] = useState<string>('')
  const { getCaptcha } = useCaptchaAsync()
  const [{ number1, number2, token }, setCaptcha] = useState<CaptchaResponse>({
    number1: null,
    number2: null,
    token: null,
  })
  const [status, setStatus] = useState<StatusForm>(StatusForm.IDLE)
  const [firstCaptcha, setFirstCaptcha] = useState<boolean>(true)

  const { submit } = useCompanyForm()

  const handleResult = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setResult(target.value)
  }

  const handleCaptcha = () => {
    if (firstCaptcha) {
      getCaptcha().then((data) => {
        setCaptcha(data)
        setFirstCaptcha(false)
      })
    }
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    console.log(event.currentTarget.checkValidity())
    setStatus(StatusForm.LOADING)
    const values = {
      name: name.value,
      surname: surname.value,
      telephone: `${code} ${telephone.value}`,
      email: email.value,
      message: message.value,
      result: +result,
      token,
    }
    submit(companyId, values)
      .then(() => {
        setStatus(StatusForm.SUCCESS)
        resetEmail()
        resetSurnames()
        resetMessage()
        resetTelephone()
        resetName()
      })
      .catch((err) => {
        if (
          err.payload.error === 'Bad captcha' ||
          err.payload.error === 'jwt expired'
        ) {
          getCaptcha().then((data) => {
            setCaptcha(data)
            setResult('')
            setStatus(StatusForm.ERROR_CAPTCHA)
          })
        } else {
          setStatus(StatusForm.ERROR)
        }
      })
  }

  const onChangeCode = (newValue: string) => {
    setCode(newValue)
  }

  return {
    name,
    surname,
    telephone,
    email,
    message,
    result,
    code,
    onChangeCode,
    handleCaptcha,
    handleSubmit,
    handleResult,
    status,
    number1,
    number2,
  }
}
