export interface CompanyJSON {
  id: string
  name: string
  email?: string
  date_entered: string
  date_modified: string
  modified_user_id: string
  created_by: string
  description: string
  deleted: number
  assigned_user_id: string
  member_since: string
  services: string
  nif: string
  videos: Array<Video>
  employees: number
  experience: string
  logo_img: string
  header_img: string
  header_img_mobile: string
  categories: Array<Category>
  logo_with_text_img: string
  tags: Array<Tag>
}

export interface Response {
  hidden_posts: Array<Post>
  hidden_accounts: Array<CompanyJSON>
  accounts: Array<CompanyJSON>
  categories: Array<Category>
  posts: Array<Post>
}

export interface Category {
  category_id: string
  category_name: string
  category_image: string
  tags: Array<Tag>
}

export interface Tag {
  category_id: string
  tag_name: string
  tag_id: string
  category_name: string
}

export interface CompanyForm {
  name: string
  surname: string
  telephone: string
  message: string
  email: string
  token: string
  result: number
}

export interface InscribeForm {
  companyName: string
  nif: string
  country: string
  cp: string
  webPage: string
  socialDenomination: string
  managerName: string
  managerEmail: string
  managerTelephoneCode: string
  managerTelephone: string
  managerSurname: string
  representants: Array<Representant>
  description: string
  servicesDescription: string
  employesNumber: string
  services: Array<{
    category: Pick<Category, 'category_id' | 'category_name'>
    tags: Array<Pick<Tag, 'tag_id' | 'tag_name'>>
  }>
  step: number
  token: string
  result: string
  number1: number
  number2: number
}

export interface ADSInscribeFormField {
  value: string
  label: string
}
export interface ADSInscribeForm {
  'company-name': ADSInscribeFormField
  'company-social-name': ADSInscribeFormField
  'company-nif': ADSInscribeFormField
  'company-city': ADSInscribeFormField
  'company-country': ADSInscribeFormField
  'company-web': ADSInscribeFormField
  'company-employees': ADSInscribeFormField
  'contact-name': ADSInscribeFormField
  'contact-surnames': ADSInscribeFormField
  'contact-email': ADSInscribeFormField
  'contact-phone': ADSInscribeFormField
  'certification-services': ADSInscribeFormField
  'certification-service-type': ADSInscribeFormField
  'certification-service-description': ADSInscribeFormField
  'certification-managers-data': ADSInscribeFormField
}

export interface Representant {
  name: string
  email: string
  telphone: string
  position: string
  telephoneCode?: string
}

export interface Post {
  id: string
  name: string
  date_entered: string
  date_modified: string
  modified_user_id: string
  created_by: string
  description: string
  deleted: number
  assigned_user_id: string
  head_image: string | null
  account_id: string
  category_id: string
  category_name: string
  category_image: string
  account_name: string
  account_logo_img: string
  publication_date: string
}

export interface Video {
  title: string
  url: string
}

export interface CaptchaResponse {
  number1: number
  number2: number
  token: string
}

export interface ItemAutocomplete {
  label: string
  score: number
  url?: string
}

export interface EntryAutocomplete {
  name: string
  items: Array<ItemAutocomplete>
}

export enum StatusForm {
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
  IDLE = 'idle',
  ERROR_CAPTCHA = 'error-captcha',
}
