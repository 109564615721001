import React from 'react'
import './FilterPill.sass'

interface FilterPillProps {
  value: string
  handleClose: (pillName: string) => void
}

const FilterPill = ({ value, handleClose }: FilterPillProps) => {
  return (
    <div className="pill" onClick={() => handleClose(value)}>
      <span className="icon icon-cross" role="button" />
      <span className="name">{value}</span>
    </div>
  )
}

export default FilterPill
