import React from 'react'
import type { Post } from 'src/server/types'
import BlogPostCard from '../BlogPostCard/BlogPostCard'

import './PostCards.sass'

interface PostCardsProps {
  posts: Array<Post>
  numberOfItems?: number
}

const PostCards = ({ posts, numberOfItems }: PostCardsProps) => {
  const postCards = numberOfItems ? posts.slice(0, numberOfItems) : posts
  return (
    <>
      {postCards
        .map((post) => (
          <BlogPostCard key={post.id} post={post} />
        ))}
    </>
  )
}

export default PostCards
