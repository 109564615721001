import React, { memo, MutableRefObject, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logoWhite from 'src/static/vectors/app-logo.svg'
import logoPainted from 'src/static/vectors/app-logo-painted.svg'
import Searchbar from '../Searchbar/Searchbar'
import { useScrollOver, useAtTop } from '@gluedigital/scrollastic'
import { FormattedMessage } from 'react-intl'

import './Navbar.sass'

interface NavBarProps {
  scrollable?: MutableRefObject<HTMLElement>
  logoColor?: boolean
}

const Navbar = ({ logoColor, scrollable }: NavBarProps) => {
  const [open, setIsOpen] = useState<boolean>(false)
  const atTop = useAtTop(scrollable)
  const value = useScrollOver('#what-is', scrollable)
  const between = !(value >= 1)

  useEffect(() => {
    if (atTop) {
      setSearch(false)
    }
  }, [atTop])

  const [search, setSearch] = useState<boolean>(false)

  return (
    <nav
      id="navbar"
      className={`${atTop ? 'top' : 'moved'} ${open ? 'open' : ''} `}
      about="menu"
    >
      <div className="beta-ribbon">Beta</div>
      <ul className={`menu container ${logoColor ? 'black' : ''}`}>
        <li className="logo">
          <Link
            to={'/'}
            onClick={() => {
              if (open) {
                setIsOpen(false)
              }
            }}
          >
            {(!logoColor && atTop) || open ? (
              <img src={logoWhite} alt="logo" />
            ) : (
              <img src={logoPainted} alt="logo" />
            )}
          </Link>
        </li>
        {open && (
          <li id="search" className="item">
            <Searchbar />
          </li>
        )}

        <li className={`item info ${search ? 'hide' : 'show'}`}>
          <a
            className={`${between && value !== 0 ? 'current' : ''}`}
            href="/#info"
            onClick={() => {
              if (open) {
                setIsOpen(false)
              }
            }}
          >
            <span>
              <FormattedMessage id="nav.what-is" />
            </span>
          </a>
        </li>

        <li className={`item info ${search ? 'hide' : 'show'}`}>
          <a
            className={`${value === 0 ? '' : between ? '' : 'current'}`}
            href="/#explore"
            onClick={() => {
              if (open) {
                setIsOpen(false)
              }
            }}
          >
            <span>
              <FormattedMessage id="nav.find-provider" />
            </span>
          </a>
        </li>

        <li className={`item info ${search ? 'hide' : 'show'}`}>
          <NavLink to="/inscribe" activeClassName="current">
            <span>
              <FormattedMessage id="nav.be-provider" />
            </span>
          </NavLink>
        </li>
        <li className={`item info ${search ? 'hide' : 'show'}`}>
          <NavLink to="/blog" activeClassName="current">
            <span>
              <FormattedMessage id="nav.blog" />
            </span>
          </NavLink>
        </li>
        {search && (
          <li className={'only-desktop item'}>
            <Searchbar />
          </li>
        )}

        <li className={`item info lens ${search ? 'show' : ''}`}>
          <span
            role="button"
            className="icon icon-lens"
            onClick={() => setSearch(!search)}
          />
        </li>
        <div className="buttons">
          <li onClick={() => setIsOpen(!open)}>
            {open ? (
              <span className="icon icon-cross" />
            ) : (
              <span className="icon icon-menu" />
            )}
          </li>
        </div>
      </ul>
    </nav>
  )
}

export default memo(Navbar)
