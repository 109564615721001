import React from 'react'

interface CountryRowProps {
  option: { id: number; value: string }
}

const CountryRow = ({ option: { value, id } }: CountryRowProps) => {
  return (
    <>
      <div
        className="flag"
        style={{
          width: 24,
          height: 24,
          backgroundImage: 'url("/images/countries-flags.png")',
          backgroundPositionX: ((id - 1) % 25) * -24,
          backgroundPositionY: (Math.ceil(id / 25) - 1) * -24,
        }}
      />
      <span>{value}</span>
    </>
  )
}

export default CountryRow
