import React, { memo, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { processServices } from 'src/utils/company'
import { processCategories } from 'src/utils/categories'
import type { CompanyJSON } from 'src/server/types'
import { useQuery } from 'src/hooks'

import './CompanyCard.sass'
import { createSlug } from 'src/utils/slug'
import TagTooltip from 'src/components/Tags/TagTooltip/TagTooltip'

interface CompanyCardProps {
  company: CompanyJSON
}

const CompanyCard = ({ company }: CompanyCardProps) => {
  const { tags, categories, name, logo_with_text_img } = company
  const query = useQuery()
  const search = query.get('query')

  const memoiezedTags = useMemo(
    () =>
      processServices(
        tags.map((tag) => tag.tag_name),
        3,
      ),
    [tags],
  )
  const memoizedCategories = useMemo(
    () =>
      processCategories(
        categories.map(({ category_name }) => category_name),
        4,
      ),
    [categories],
  )

  const location = {
    pathname: `/company/${createSlug(company)}`,
    state: {
      search,
    },
  }

  return (
    <Link to={location} className="company-result">
      <div
        className="image-container"
        style={{ backgroundImage: `url(${logo_with_text_img})` }}
      />
      <p className="name">{name}</p>
      <p className="categories">{memoizedCategories}</p>
      <ul className="tags" aria-labelledby="tags-items">
        {memoiezedTags.map((tag, index) => {
          if (index === memoiezedTags.length - 1) {
            return (
              <TagTooltip tag={tag} tags={tags.map((tag) => tag.tag_name)} />
            )
          }
          return <li key={tag}>{tag}</li>
        })}
      </ul>
    </Link>
  )
}

export default memo(CompanyCard)
