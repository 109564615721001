import {
  useFetch,
  useFetchUncachedCb,
  usePaginatedFetch,
} from '@gluedigital/ruse-fetch-extras'
import type {
  CaptchaResponse,
  Category,
  CompanyForm,
  CompanyJSON,
  InscribeForm,
  Post,
} from 'src/server/types'

const base = process.env.BASE_API
  ? process.env.BASE_API
  : 'http://localhost:3000/api'

export const useCompanies = () =>
  useFetch<Array<CompanyJSON>>(`${base}/companies`)

export const useCompany = (id: string) =>
  useFetch<CompanyJSON>(`${base}/companies/${id}`)

export const useCompanyTrack = (id: string) =>
  useFetch<CompanyJSON>(`${base}/companies/${id}?track=true`)

export const useCategories = () =>
  useFetch<Array<Category>>(`${base}/categories`)

export const useCaptcha = () => useFetch<CaptchaResponse>(`${base}/captcha`)

export const useCaptchaAsync = () => {
  const doFetch = useFetchUncachedCb()
  return {
    getCaptcha: () => doFetch<CaptchaResponse>(`${base}/captcha`),
  }
}
export const usePosts = () => useFetch<Array<Post>>(`${base}/posts`)

export const usePost = (id: string) => useFetch<Post>(`${base}/posts/${id}`)
export const useLastPosts = () => useFetch<Post>(`${base}/posts/last`)

export const useSubmitInscribe = () => {
  const doFetch = useFetchUncachedCb()
  return {
    submit: (data: Omit<InscribeForm, 'number1' | 'number2'>) =>
      doFetch(`${base}/companies/inscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
  }
}

export const useCompanyForm = () => {
  const doFetch = useFetchUncachedCb()
  return {
    submit: (companyId: string, data: CompanyForm) =>
      doFetch(`${base}/companies/${companyId}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
  }
}

export const usePaginatedPosts = () =>
  usePaginatedFetch<Post[]>(`${base}/posts/paginated`, {
    hasNext: (e) => e.length === 12,
  })
