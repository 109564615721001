import { useState } from 'react'

export const useField = () => {
  const [value, setValue] = useState<string>('')

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setValue(event.target.value)
  }

  const reset = () => {
    setValue('')
  }

  return {
    value,
    onChange,
    reset,
  }
}
