import React, { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import PostCards from 'src/components/PostCards/PostCards'
import { usePosts } from 'src/hooks/api'

const HomePosts = () => {
  const posts = usePosts()

  return (
    <section className="blog-posts lime" id="blog">
      <div className="container">
        <div className="blog-posts-heading">
          <h2>
            <FormattedMessage id="home.blog.title" />
          </h2>
          <Link to="/blog">
            <FormattedMessage id="home.blog.title.see-posts" />
          </Link>
        </div>
        <div className="card-container">
          <PostCards posts={posts} numberOfItems={3} />
        </div>
      </div>

    </section>
  )
}

const HomePostsWrapper = () => (
  <Suspense>
    <HomePosts />
  </Suspense>
)

export default HomePostsWrapper
