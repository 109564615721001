import React, { forwardRef } from 'react'
import { Post } from 'src/server/types'
import BlogPostCard from '../BlogPostCard/BlogPostCard'
import BlogPostCardPlaceholder from '../BlogPostCard/BlogPostCardPlaceholder/BlogPostCardPlaceholder'

import './PostGrid.sass'

interface PostGridProps {
  posts: Post[]
  isLoadingMore?: boolean
}

const PostGrid = forwardRef<any, PostGridProps>(
  ({ posts, isLoadingMore = false }, ref) => {
    const featured = posts?.slice(0, 3)
    const normal = posts?.slice(3)
    const placeholderNumber = normal.length % 2 === 0 ? 4 : 3

    return (
      <div id="post-grid" ref={ref}>
        <div className="grid featured-grid">
          {featured.map((post) => (
            <BlogPostCard
              key={post.id}
              post={post}
              important={true}
              showCategory
            />
          ))}
        </div>
        <div className="grid normal-grid">
          {normal.map((post) => (
            <BlogPostCard
              key={post.id}
              post={post}
              important={true}
              showCategory
            />
          ))}

          {isLoadingMore &&
            [...Array(placeholderNumber)].map((_e, index) => (
              <BlogPostCardPlaceholder key={index} />
            ))}
        </div>
      </div>
    )
  },
)

PostGrid.displayName = 'PostGrid'

export default PostGrid
