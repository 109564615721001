import React, { Suspense, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb'
import CategorySelectRow from 'src/components/CategorySelectRow/CategorySelectRow'
import CustomSelect from 'src/components/CustomSelect/CustomSelect'
import Footer from 'src/components/Footer/Footer'
import Loading from 'src/components/Loading/Loading'
import Navbar from 'src/components/Navbar/Navbar'
import Pages from 'src/components/Pages/Pages'
import Searchbar from 'src/components/Searchbar/Searchbar'
import { useResults } from 'src/hooks'
import { useCategories } from 'src/hooks/api'
import CategoriesPills from './CategoriesPills/CategoriesPills'
import CompanyCard from './CompanyCard/CompanyCard'

import './Results.sass'

const Results = () => {
  const {
    handleCategory,
    categoriesSelected,
    companiesShowed,
    query,
    pages,
    handleTooltip,
    showTooltip,
    closeTooltip,
  } = useResults()

  const categories = useCategories()
  const memoedOptionsSelect = useMemo(
    () =>
      categories
        .map((category) => ({
          value: category.category_name,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [categories],
  )

  return (
    <div id="results-search" className="page">
      <Navbar logoColor={true} />
      <section id="header" className="container">
        <Breadcrumb />
        <h2>
          {query ? (
            <FormattedMessage
              id="results.title-result"
              values={{ italic: <span className="italic">{query}</span> }}
            />
          ) : (
            <FormattedMessage id="results.title-noresult" />
          )}
        </h2>
        <Searchbar />
        <div id="utils">
          <CustomSelect
            LabelComponent={
              <span className="select-label">
                <strong>
                  <FormattedMessage id="categories-select.name" />
                </strong>
              </span>
            }
            OptionComponent={CategorySelectRow}
            onChange={{
              add: handleCategory.add,
              remove: handleCategory.remove,
            }}
            options={memoedOptionsSelect}
            value={categoriesSelected}
          />

          <CategoriesPills
            categories={categoriesSelected}
            removeCategory={handleCategory.remove}
          />
        </div>
      </section>

      <section id="companies" className="container">
        <div id="companies-grid">
          {companiesShowed.length === 0 && (
            <p>
              <FormattedMessage id="results.no-results" />
            </p>
          )}
          {companiesShowed.map((company) => (
            <CompanyCard key={company.id} company={company} />
          ))}
        </div>
        <Pages length={pages} />
      </section>
      <div className="help-results">
        <span onFocus={handleTooltip} onBlur={closeTooltip} tabIndex={1}>
          <FormattedMessage id="results.how-order-results" />
        </span>
        <div className={'help-results-info'}>
          <div className={`${showTooltip ? 'show' : ''}`}>
            <FormattedMessage id="results.how-order-results-explanation" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

const ResultsWrapper = () => (
  <Suspense fallback={<Loading />}>
    <Results />
  </Suspense>
)

export default ResultsWrapper
