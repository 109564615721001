import { useEffect, useRef } from 'react'

const useInfiniteScroll = (
  callback: () => void,
  useParent: boolean | number = false,
) => {
  const ref = useRef<HTMLElement>()

  useEffect(() => {
    let node = ref.current
    let goUp = typeof useParent === 'number' ? useParent : useParent ? 1 : 0
    while (goUp > 0) {
      node = node.parentElement
      goUp--
    }

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = node
      const remainingPx = scrollHeight - clientHeight - scrollTop
      if (remainingPx <= 700) {
        callback && callback()
      }
    }

    node.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => node.removeEventListener('scroll', handleScroll)
  }, [callback, useParent])

  return ref
}

export default useInfiniteScroll
