import { useRef, useState } from 'react'

export const useVideo = () => {
  const [play, setPlay] = useState(false)
  const videoRef = useRef<HTMLVideoElement>()

  const playVideo = () => {
    setPlay(true)
    videoRef.current.play()
    videoRef.current.controls = true
  }

  const pauseVideo = () => {
    setPlay(false)
    videoRef.current.controls = false
  }

  return {
    play,
    videoRef,
    playVideo,
    pauseVideo,
  }
}
