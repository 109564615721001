import React from 'react'

import './BlogSlideHandler.sass'

interface BlogSlideHandlerProps {
  currentSlide?: number
  totalSlides?: number
  slideTo?: (slide: number) => void
}

const BlogSlideHandler = ({ currentSlide, totalSlides, slideTo }: BlogSlideHandlerProps) => {

  return (
    <div className="blog-slide-handler">
      <div className="icon icon-left" onClick={() => slideTo(currentSlide - 1)} />
      {
        [...Array(totalSlides)].map((d, i) => {
          return (
            <div
              onClick={() => slideTo(i)}
              key={i + (d + '')}
              className={`dot ${ i === currentSlide ? 'active' : '' }`} />
          )
        }
      )}
      <div className="icon icon-right" onClick={() => slideTo(currentSlide + 1)} />
    </div>
  )
}

export default BlogSlideHandler
