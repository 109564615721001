import React from 'react'
import { FormattedMessage } from 'react-intl'

import './Loading.sass'

const Loading = () => {
  return (
    <div className="loading">
      <div className="wrapper">
        <div className="spinner">
          <img src="/vectors/spinner.svg" alt="spinner vector" />
        </div>
        <div className="text">
          <FormattedMessage id="loading" />
        </div>
      </div>
    </div>
  )
}

export default Loading
