import React from 'react'
import { Link, Route, Switch, useLocation, useParams } from 'react-router-dom'
import { useQuery } from 'src/hooks'
import { useCompany } from 'src/hooks/api'

import './Breadcrumb.sass'

const Breadcrumb = () => {
  return (
    <div className="history">
      <InitialCrumb />
      <Switch>
        <Route path={'/results'}>
          <CrumbResults />
        </Route>
        <Route path={'/company/:id'}>
          <CrumbCompanies />
        </Route>
      </Switch>
    </div>
  )
}

export default Breadcrumb

const InitialCrumb = () => (
  <Link className="" to={'/'}>
    Digital Marketplace
  </Link>
)

const CrumbResults = () => {
  const query = useQuery()
  const search = query.get('query')

  return search && <span>{search}</span>
}

const CrumbCompanies = () => {
  const { id } = useParams<{ id: string }>()
  const { name } = useCompany(id)
  const { state } = useLocation<{ search: string | null }>()
  if (state?.search) {
    return (
      <>
        <Link to={`/results?query=${state.search}`}>{state.search}</Link>
        <span className="company-name">{name}</span>
      </>
    )
  }
  return <span className="company-name">{name}</span>
}
