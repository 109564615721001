import React from 'react'

import './Captcha.sass'

interface CaptchaProps {
  number1: number
  number2: number
  result: string
  handleResult: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Captcha = ({ handleResult, number1, number2, result }: CaptchaProps) => {
  return (
    <div id="captcha-company">
      <strong>
        {number1} + {number2} =
      </strong>
      <input
        name="result"
        type="number"
        onChange={handleResult}
        value={result}
      />
    </div>
  )
}

export default Captcha
