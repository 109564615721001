import React from 'react'

import defaultPoster from 'src/static/images/delete.jpg'
import './CustomVideo.sass'

interface CustomVideoProps {
  video: string
  pauseVideo: () => void
  playVideo: () => void
  videoRef: React.MutableRefObject<HTMLVideoElement>
  play: boolean
}

const CustomVideo = ({
  video,
  pauseVideo,
  playVideo,
  videoRef,
  play,

}: CustomVideoProps) => {
  const poster = video.split('.').slice(0, -1).join('.') + '.png'
  return (
    <div className="video-card">
      <video
        ref={videoRef}
        onPause={pauseVideo}
        poster={poster !== '.png' ? poster : defaultPoster}
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className={`play ${play ? 'hide' : ''}`} onClick={playVideo}>
        <span
          className={`icon icon-play ${play ? 'hide' : ''}`}
          onClick={playVideo}
        />
      </div>
    </div>
  )
}

export default CustomVideo
