import type { CompanyJSON } from 'src/server/types'
import { removeNoScore, sortScore } from './search/search'

export const processCategories = (
  categories: Array<string>,
  index: number,
): string => {
  if (categories.length <= index) {
    return categories.join(', ')
  }
  const categoriesReduced = categories.slice(0, index).join(', ')
  return `${categoriesReduced} ...(+${categories.length - index})`
}

export const getCompaniesByCategory = (
  companies: Array<CompanyJSON>,
  categoriesSelected: Array<string>,
): Array<CompanyJSON> => {
  if (categoriesSelected.length === 0) {
    return companies
  }
  const companiesFiltered = companies
    .map((company) => {
      const categoriesNameSelected = company.categories.filter(
        (category) => categoriesSelected.indexOf(category.category_name) !== -1,
      )
      return { ...company, score: categoriesNameSelected.length }
    })
    .flat()
    .filter(removeNoScore)
    .sort(sortScore)
  return companiesFiltered
}
