import type { CompanyJSON, Post } from 'src/server/types'

interface CompanyJSONWithPosts extends CompanyJSON {
  posts: Array<Post>
}

const score = (company: CompanyJSONWithPosts, search: string): number => {
  const { categories, tags, services, description, name, posts } = company
  let score = 0

  const categoriesFilter = categories.filter(({ category_name }) =>
    category_name.toLowerCase().includes(search),
  )

  const tagsFilter = tags.filter(({ tag_name }) =>
    tag_name.toLowerCase().includes(search),
  )

  const servicesFilter = services.toLowerCase().includes(search)

  const descriptionFilter = description.toLowerCase().includes(search)

  const nameFilter = name.toLowerCase().includes(search)

  const postsFilter = posts.filter(({ description }) =>
    description.toLowerCase().includes(search),
  )

  score += categoriesFilter.length * 25
  score += tagsFilter.length * 20
  if (servicesFilter) score += 15
  if (descriptionFilter) score += 10
  if (nameFilter) score += 5
  score += postsFilter.length * 2.5

  return score
}

export const removeNoScore = <T extends { score: number }>(element: T) =>
  element.score !== 0

export const sortScore = <T extends { score: number }>(
  companyA: T,
  companyB: T,
): number => {
  const diff = companyB.score - companyA.score
  if (diff !== 0) return diff
  const random = Math.random()
  return random <= 0.5 ? 1 : -1
}

export const search = (
  search: string,
  file: Array<CompanyJSON>,
  posts: Array<Post>,
): Array<CompanyJSON> => {
  if (!search || search.length === 0)
    return file.map((company) => ({ ...company, score: 0 })).sort(sortScore)

  search = search.toLowerCase()

  const orderedResults = file
    .map((company) => ({
      ...company,
      posts: posts.filter(({ account_id }) => company.id === account_id),
    }))
    .map((companyWithPosts) => ({
      ...companyWithPosts,
      score: score(companyWithPosts, search),
    }))
    .filter(removeNoScore)
    .sort(sortScore)

  return orderedResults
}
