export const paginate = <T>(
  array: Array<T>,
  limit: number,
): Array<Array<T>> => {
  const result: Array<Array<T>> = [[]]
  array.forEach((element, index) => {
    const position = Math.floor(index / limit)
    if (!result[position]) {
      result[position] = []
    }
    result[position].push(element)
  })
  return result
}
