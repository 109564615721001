import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import { ModalWrapper, Modal } from '@gluedigital/modal'
import Home from './Home/Home'
import Results from './Results/Results'
import Company from './Company/Company'
import Inscribe from './Inscribe/Inscribe'
import Blog from './Blog/Blog'
import BlogPost from './BlogPost/BlogPost'
import Category from './Category/Category'
import CompanyPosts from './CompanyPosts/CompanyPosts'
import Banner from 'src/components/Consent/Banner'
import Analytics from 'src/components/Consent/Analytics'
import NotFound from './NotFound/NotFound'
import Meta from 'src/components/Meta/Meta'

declare let __CLIENT__: boolean

const App = () => {
  return (
    <ModalWrapper>
      <main id="app">
        <Meta />
        <ErrorBoundary>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/results" exact>
              <Results />
            </Route>
            <Route path="/company/:id/:slug?">
              <Company />
            </Route>
            <Route path="/inscribe" exact>
              <Inscribe />
            </Route>
            <Route path="/blog" exact>
              <Blog />
            </Route>
            <Route path="/blog/:id/:slug?">
              <BlogPost />
            </Route>
            <Route path="/category/:id">
              <Category />
            </Route>
            <Route path="/company-posts/:id/:slug?">
              <CompanyPosts />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </ErrorBoundary>
        {__CLIENT__ && <Banner />}
        {__CLIENT__ && <Analytics />}
        <Modal />
      </main>
    </ModalWrapper>
  )
}

export default App
