import React from 'react'

interface BannerContentProps {
  handleAccept: () => void
  handleReject: () => void
}

const BannerContent = ({ handleAccept, handleReject }: BannerContentProps) => {
  return (
    <div id="consent-banner">
      <div className="banner-card">
        <h1>Política de cookies</h1>
        <p>
          <span>
            El uso de cookies propias y de terceros para mejorar nuestros
            servicios, mediante el análisis de sus hábitos de navegación. Si
            continúa navegando, consideramos que rechaza su uso, siendo posible
            que diversas funcionalidades de la web no se encuentren disponible.
            Puede obtener más información y configurar sus preferencias.
          </span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.adigital.org/politica-proteccion-datos-cookies-redes-sociales/#7"
          >
            <strong> Ver más detalles</strong>
          </a>
        </p>
        <div className="buttons">
          <button className="button-accept secondary" onClick={handleAccept}>
            <span>Aceptar</span>
          </button>
          <button className="button-reject" onClick={handleReject}>
            <span>Rechazar</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default BannerContent
