import React from 'react'
import useConsent from 'src/components/Consent/useConsent'
import BannerContent from './BannerContent'

import './BannerAlt.sass'

interface BannerAltProps {
  closeBanner: () => void
}

const BannerAlt = ({ closeBanner }: BannerAltProps) => {
  const { accept, reject } = useConsent()

  const handleAccept = () => {
    accept()
    closeBanner()
  }

  const handleReject = () => {
    reject()
    closeBanner()
  }

  return (
    <BannerContent handleAccept={handleAccept} handleReject={handleReject} />
  )
}

export default BannerAlt
