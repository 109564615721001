import React, { ReactElement } from 'react'

import './Slides.sass'

interface SlidesProps {
  slides: ReactElement[]
}

const Slides = ({ slides = []}: SlidesProps) => {
  return (
    <>
      {slides.map(s => s)}
    </>
  )
}

export default Slides
