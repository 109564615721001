import { accept, reject } from './actions/consent'

declare let __CLIENT__: boolean

const consentToLocalStorage = ({ getState, dispatch }) => {
  let state = getState().consent
  setTimeout(() => {
    const savedState = localStorage.getItem('_consent')
    if (savedState === '1') dispatch(accept())
    if (savedState === '0') dispatch(reject())
  }, 0)

  return (next) => (action) => {
    const ret = next(action)
    const newState = getState().consent
    if (state !== newState) {
      localStorage.setItem('_consent', newState ? '1' : '0')
      state = newState
    }
    return ret
  }
}

const middlewares = []
if (__CLIENT__) middlewares.push(consentToLocalStorage)

export default middlewares
