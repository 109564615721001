import React from 'react'
import { Post } from 'src/server/types'
import BlogPostCard from '../BlogPostCard/BlogPostCard'

import './BlogSlides.sass'

interface BlogSlidesProps {
  slides: Post[]
}

const BlogSlides = ({ slides = []}: BlogSlidesProps) => {
  return (
    <>
      {slides.map(s =>
        <BlogPostCard key={s.id} post={s} />)
      }
    </>
  )
}

export default BlogSlides
