import React from 'react'
import FilterPill from 'src/components/FilterPill/FilterPill'

interface CategoriesPillsProps {
  categories: Array<string>
  removeCategory: (categoryToRemove: string) => void
}

const CategoriesPills = ({
  categories,
  removeCategory,
}: CategoriesPillsProps) => {
  return (
    <section className="category-pills-container">
      {categories.map((category) => (
        <FilterPill
          key={category}
          value={category}
          handleClose={() => removeCategory(category)}
        />
      ))}
    </section>
  )
}

export default CategoriesPills
