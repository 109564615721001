import React from 'react'
import HiddenLink from '../HiddenLink/HiddenLink'

import './CompanyInfo.sass'

interface CompanyInfoProps {
  name: string
  image: string
  id: string
  bigSize?: boolean
  noLink?: boolean
}

const CompanyInfo = ({
  image,
  name,
  id,
  bigSize = false,
  noLink = false,
}: CompanyInfoProps) => {
  return (
    <HiddenLink noLink={noLink} bigSize={bigSize} id={id} name={name}>
      <div className="image-wrapper">
        <img src={image} alt={`${name} company logo`} />
      </div>
      <div className="data-wrapper">
        <span className="company-name">{name}</span>
      </div>
    </HiddenLink>
  )
}

export default CompanyInfo
