import { useSelector, useDispatch } from 'react-redux'
import { accept, reject } from 'src/store/actions/consent'

interface ConsentHandler {
  accept: () => void
  reject: () => void
  gotAnswer: boolean
  gotConsent: boolean
}

const useConsent = (): ConsentHandler => {
  const dispatch = useDispatch()
  const currStatus: boolean = useSelector((s: any) => s.consent)

  const gotAnswer: boolean = currStatus === true || currStatus === false

  const setAnswer = (answer) => {
    dispatch(answer ? accept() : reject())
    if (gotAnswer && currStatus && !answer) {
      setTimeout(() => window.location.reload(), 0)
    }
  }

  return {
    accept: () => setAnswer(true),
    reject: () => setAnswer(false),
    gotAnswer,
    gotConsent: currStatus,
  }
}

export default useConsent
