import React, { useCallback, useState } from 'react'
import { useCaptchaAsync, useSubmitInscribe } from 'src/hooks/api'
import { InscribeForm, StatusForm } from 'src/server/types'
import countries from '../../../misc/world.json'

export const useInscribeForm = ({ stepsNumber }: { stepsNumber: number }) => {
  const { getCaptcha } = useCaptchaAsync()
  const [status, setStatus] = useState<StatusForm>(StatusForm.IDLE)

  const { submit } = useSubmitInscribe()

  const emptyInscribeCompany: InscribeForm = {
    companyName: null,
    country: countries[0].name || 'España',
    cp: null,
    nif: null,
    webPage: null,
    description: null,
    servicesDescription: null,
    services: [],
    representants: [
      {
        email: '',
        name: '',
        position: '',
        telphone: '',
        telephoneCode: countries[0].code || '34',
      },
    ],
    socialDenomination: null,
    employesNumber: null,
    managerName: null,
    managerEmail: null,
    managerTelephoneCode: countries[0].code || '34',
    managerTelephone: null,
    managerSurname: null,
    step: 0,
    token: null,
    result: '',
    number1: null,
    number2: null,
  }

  const [inscribeForm, setInscribeForm] = useState(emptyInscribeCompany)

  const handleInscribeForm = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) =>
    setInscribeForm({
      ...inscribeForm,
      [event.target.name]: event.target.value,
    })

  const handleCaptcha = useCallback(
    () =>
      (async () => {
        const { number1, number2, token } = await getCaptcha()
        setInscribeForm((prev) => ({
          ...prev,
          token,
          number1,
          number2,
        }))
      })(),
    [getCaptcha],
  )

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (inscribeForm.step === 2 && inscribeForm.services.length === 0) {
      return
    } else if (stepsNumber - 1 === inscribeForm.step) {
      setStatus(StatusForm.LOADING)
      inscribeForm.managerTelephone = `${inscribeForm.managerTelephoneCode} ${inscribeForm.managerTelephone}`
      delete inscribeForm.managerTelephoneCode

      inscribeForm.representants = inscribeForm.representants.map(
        (representant) => {
          const { email, name, position, telephoneCode, telphone } =
            representant
          return {
            email,
            name,
            position,
            telphone: `${telephoneCode} ${telphone}`,
          }
        },
      )
      submit(inscribeForm)
        .then(() => setStatus(StatusForm.SUCCESS))
        .catch(async (err) => {
          if (
            err.payload.error === 'Bad captcha' ||
            err.payload.error === 'jwt expired'
          ) {
            await handleCaptcha()
            setStatus(StatusForm.ERROR_CAPTCHA)
          } else {
            setStatus(StatusForm.ERROR)
          }
        })
      return
    }
    const prevStep = inscribeForm.step
    setInscribeForm({ ...inscribeForm, step: prevStep + 1 })
  }

  const goPreviousStep = () => {
    const prevStep = inscribeForm.step
    setInscribeForm({ ...inscribeForm, step: prevStep - 1 })
  }

  return {
    inscribeForm,
    handleInscribeForm,
    handleSubmit,
    setInscribeForm,
    goPreviousStep,
    status,
  }
}
