import countries from '../../../misc/world.json'

export const processCountryCode = () => {
  const [spain, ...rest] = countries
  const procesedRest = rest
    .map((country) => {
      const positionToRemove = country.code.indexOf('-')
      if (positionToRemove !== -1) {
        return {
          id: country.id,
          value: country.code.substring(0, positionToRemove),
        }
      }
      return { id: country.id, value: country.code }
    })
    .sort((a, b) => (+a.value > +b.value ? 1 : -1))
    .map((country) => ({ ...country, value: `+${country.value}` }))
  const newSpain = {
    id: spain.id,
    value: `+${spain.code}`,
  }
  return [newSpain, ...procesedRest]
}
