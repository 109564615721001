import React from 'react'
import './TagTooltip.sass'

interface TagTooltipProps {
  tags: Array<string>
  tag: string
}

const TagTooltip = ({ tag, tags }: TagTooltipProps) => {
  return (
    <li className="tag-tooltip">
      <div className={'tooltip'}>
        {tags.map((tag, index) => (
          <span key={index}>{tag}</span>
        ))}
      </div>
      <span tabIndex={0} key={tag}>
        {tag}
      </span>
    </li>
  )
}

export default TagTooltip
