import React, { useCallback } from 'react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import TelephoneContact from 'src/components/TelephoneContact/TelephoneContact'
import type { InscribeForm, Representant, StatusForm } from 'src/server/types'

import './Representative.sass'

interface RepresentativeProps {
  inscribeForm: InscribeForm
  handleInscribeForm: (event: React.ChangeEvent) => void
  setInscribeForm: (value: React.SetStateAction<InscribeForm>) => void
  status: StatusForm
}

interface RepresentativeInputsProps {
  handleTelephoneContactCode: (newValue: string) => void
  handleAddValue: (event: React.ChangeEvent<HTMLInputElement>) => void
  data: Representant
  required: boolean
}

const RepresentativeInputs = ({
  handleAddValue,
  data,
  required,
  handleTelephoneContactCode,
}: RepresentativeInputsProps) => {
  const intl = useIntl()

  const { email, name, position, telphone, telephoneCode } = data

  return (
    <div className="dynamic-inputs-row" onChange={handleAddValue}>
      <input
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.representative.name-surname',
        })}
        name="name"
        minLength={2}
        maxLength={128}
        required={required}
        value={name || ''}
        onChange={() => null}
      />
      <input
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.representative.position',
        })}
        name="position"
        required={required}
        value={position || ''}
        onChange={() => null}
      />
      <input
        placeholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.representative.email',
        })}
        name="email"
        maxLength={128}
        pattern=".+\.\w+$"
        type="email"
        required={required}
        value={email || ''}
        onChange={() => null}
      />
      <TelephoneContact
        codeValue={telephoneCode}
        handleCodeValue={handleTelephoneContactCode}
        handleTelephoneValue={() => null}
        inputAttrs={{ name: 'telphone' }}
        inputPlaceholder={intl.formatMessage({
          id: 'placeholder.inscribe-form.representative.telephone',
        })}
        telephoneValue={telphone || ''}
      />
    </div>
  )
}

const Representative = ({
  inscribeForm,
  setInscribeForm,
}: RepresentativeProps) => {
  const { description, servicesDescription, representants } = inscribeForm

  const intl = useIntl()
  const [error, setError] = useState<string>(null)

  const updateRepresentants = useCallback(
    (key: string, value: string, index: number) => {
      const elem = representants[index]
      const copy = [...representants]
      copy.splice(index, 1, {
        ...elem,
        [key]: value,
      })
      setInscribeForm((prevInscribeForm) => ({
        ...prevInscribeForm,
        representants: copy,
      }))
    },
    [representants, setInscribeForm],
  )

  const handleAddValue = useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { tagName },
      } = event
      if (tagName !== 'INPUT') return
      updateRepresentants(event.target.name, event.target.value, index)
    },
    [updateRepresentants],
  )

  const deleteEntry = useCallback(
    (index: number) => {
      const copyRepresentants = [...representants]
      copyRepresentants.splice(index, 1)
      setInscribeForm((prevInscribeForm) => ({
        ...prevInscribeForm,
        representants: copyRepresentants,
      }))
    },
    [representants, setInscribeForm],
  )

  const lastElement = inscribeForm.representants[0]

  return (
    <div id="representative">
      <div>
        <input
          type="checkbox"
          className="input-min-representants"
          required
          onChange={() => null}
          checked={
            description?.split(/\s+/).length <= 100 &&
            servicesDescription?.split(/\s+/).length <= 100 &&
            lastElement?.name !== '' &&
            lastElement?.email !== '' &&
            lastElement?.position !== '' &&
            lastElement?.telphone !== ''
          }
        />
        <div className="message-title">
          <h5>
            <FormattedMessage id="inscribe.inscribe-form.representative.company-description" />
          </h5>
          <span
            className={`words-length ${
              description?.split(/\s+/).length > 100 ? 'error' : ''
            }`}
          >
            {description?.split(/\s+/).length || 0}/100
          </span>
        </div>

        <textarea
          required
          placeholder={intl.formatMessage({
            id: 'inscribe.inscribe-form.representative.company-description',
          })}
          name="description"
          value={description || ''}
          onChange={(event) =>
            setInscribeForm({
              ...inscribeForm,
              description: event.target.value,
            })
          }
        />
        <div className="message-title">
          <h5>
            <FormattedMessage id="inscribe.inscribe-form.representative.services-description" />
          </h5>
          <span
            className={`words-length ${
              servicesDescription?.split(/\s+/).length > 100 ? 'error' : ''
            }`}
          >
            {servicesDescription?.split(/\s+/).length || 0}/100
          </span>
        </div>

        <textarea
          required
          placeholder={intl.formatMessage({
            id: 'inscribe.inscribe-form.representative.services-description',
          })}
          name="servicesDescription"
          value={servicesDescription || ''}
          onChange={(event) =>
            setInscribeForm({
              ...inscribeForm,
              servicesDescription: event.target.value,
            })
          }
        />
        <h5>
          <FormattedMessage id="inscribe.inscribe-form.representative.responsibles" />
        </h5>
        <div className="dynamic-inputs">
          {representants.map((val, index) => {
            return (
              <div key={index}>
                <RepresentativeInputs
                  key={index}
                  required={representants.length < 2}
                  handleAddValue={handleAddValue(index)}
                  data={val}
                  handleTelephoneContactCode={(newValue) =>
                    updateRepresentants('telephoneCode', newValue, index)
                  }
                />
                <button
                  className="only-mobile dynamic-inputs-delete"
                  onClick={() => deleteEntry(index)}
                  disabled={representants.length < 2}
                >
                  <FormattedMessage id="inscribe.inscribe-form.representative.representant-remove" />
                </button>
                <div
                  className="only-desktop dynamic-inputs-delete"
                  onClick={() => deleteEntry(index)}
                  role="button"
                >
                  <span className="icon icon-minus" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {error && <p>{error}</p>}
      <div
        className="add-dynamic-input"
        onClick={() => {
          if (representants.length === 0) {
            setError('Debes completar todos los campos para poder anadir mas')
            return
          }
          const { name, email, position, telphone } =
            representants[representants.length - 1]
          if (
            name === '' ||
            email === '' ||
            position === '' ||
            telphone === ''
          ) {
            setError('Debes completar todos los campos para poder anadir mas')
            return
          }
          setInscribeForm({
            ...inscribeForm,
            representants: [
              ...inscribeForm.representants,
              {
                email: '',
                name: '',
                telphone: '',
                position: '',
                telephoneCode: '+34',
              },
            ],
          })
          setError(null)
        }}
      >
        <div>
          <span className="icon icon-plus" />
        </div>
        <span>
          <FormattedMessage id="inscribe.inscribe-form.representative.add-responsible" />
        </span>
      </div>
    </div>
  )
}

export default Representative
