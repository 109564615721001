import React, { Suspense, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useContactForm } from 'src/hooks'
import Captcha from 'src/routes/Company/ContactModal/Captcha/Captcha'
import { StatusForm } from 'src/server/types'
import TelephoneContact from 'src/components/TelephoneContact/TelephoneContact'

import './ContactForm.sass'

interface ContactFormProps {
  companyId: string
}

const ContactForm = ({ companyId }: ContactFormProps) => {
  const {
    email,
    message,
    name,
    surname,
    telephone,
    code,
    onChangeCode,
    handleSubmit,
    handleResult,
    handleCaptcha,
    result,
    status,
    number1,
    number2,
  } = useContactForm(companyId)

  const formRef = useRef<HTMLFormElement>()

  const intl = useIntl()

  return (
    <form
      aria-label="Contact Company"
      name="contactForm"
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <div className="contact-company">
        <h3>
          <FormattedMessage id="company.contact-form.title" />
        </h3>
      </div>
      <input
        id="name"
        name="name"
        type="text"
        minLength={2}
        maxLength={128}
        placeholder={intl.formatMessage({
          id: 'placeholder.contact-form.name',
        })}
        required
        onFocus={handleCaptcha}
        {...name}
      />
      <input
        id="surname"
        name="surname"
        type="text"
        minLength={2}
        maxLength={128}
        placeholder={intl.formatMessage({
          id: 'placeholder.contact-form.surname',
        })}
        required
        onFocus={handleCaptcha}
        {...surname}
      />
      <TelephoneContact
        codeValue={code}
        handleCodeValue={onChangeCode}
        handleTelephoneValue={telephone.onChange}
        inputPlaceholder={intl.formatMessage({
          id: 'placeholder.contact-form.telephone',
        })}
        telephoneValue={telephone.value}
        inputAttrs={{ name: 'telephone' }}
      />
      <input
        id="email"
        name="email"
        type="email"
        maxLength={128}
        pattern=".+\.\w+$"
        placeholder={intl.formatMessage({
          id: 'placeholder.contact-form.email',
        })}
        required
        onFocus={handleCaptcha}
        {...email}
      />
      <textarea
        id="message"
        name="message"
        placeholder={intl.formatMessage({
          id: 'placeholder.contact-form.message',
        })}
        required
        maxLength={10000}
        onFocus={handleCaptcha}
        {...message}
      />
      <p className="check">
        <span>
          <FormattedMessage
            id="company.contact-form.check-text"
            values={{
              link: (
                <a
                  target="_blank"
                  className="link"
                  href="https://www.adigital.org/politica-proteccion-datos-cookies-redes-sociales/"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({
                    id: 'company.contact-form.check-text.privacy-policy',
                  })}
                </a>
              ),
            }}
          />
        </span>
      </p>

      <footer>
        {number1 !== null && number2 !== null && (
          <Captcha
            number1={number1}
            number2={number2}
            result={result}
            handleResult={handleResult}
          />
        )}

        <button
          disabled={status === StatusForm.LOADING}
          type="button"
          onClick={() => formRef.current.requestSubmit()}
        >
          {status === StatusForm.LOADING ? (
            <FormattedMessage id="form.submit.button-label-loading" />
          ) : (
            <FormattedMessage id="company.contact-form.button-label" />
          )}
        </button>
      </footer>

      {status === StatusForm.SUCCESS && (
        <span className="success">
          <FormattedMessage id="company.contact-form.success" />
        </span>
      )}
      {status === StatusForm.ERROR && (
        <span className="error">
          <FormattedMessage id="form.submit.error" />
        </span>
      )}
      {status === StatusForm.ERROR_CAPTCHA && (
        <span className="error">
          <FormattedMessage id="form.submit.error-captcha" />
        </span>
      )}
    </form>
  )
}

const ContactFormWrapper = (props) => (
  <Suspense>
    <ContactForm {...props} />
  </Suspense>
)

export default ContactFormWrapper
