
import React from 'react'

import './BlogHeroBanner.sass'

import accountLogoImg from 'src/static/vectors/account_logo_img.svg'


const BlogHeroBannerPlaceholder = () => {
  return (
    <header className="placeholder-banner" id="blog-hero-banner">
      <div className="container">
        <div className="header-content">
          <h2 className="placeholder-default" />
          <h1 className="placeholder-default" />
          <section className="company">
            <img className="logo" src={accountLogoImg} />
            <strong className="placeholder-default" />
          </section>
        </div>
      </div>
  </header>
  )
}

export default BlogHeroBannerPlaceholder
