import React from 'react'
import { processCountryCode } from 'src/utils/countries/countries'
import CountryRow from '../CountryRow/CountryRow'
import CustomSelect from '../CustomSelect/CustomSelect'

import './TelephoneContact.sass'

interface TelephoneContactProps {
  inputPlaceholder: string
  codeValue: string
  handleCodeValue: (newValue: string) => void
  telephoneValue: string
  handleTelephoneValue: (event: React.ChangeEvent<HTMLInputElement>) => void
  inputAttrs: React.InputHTMLAttributes<HTMLInputElement>
}

const countryCodeOptions = processCountryCode()

const TelephoneContact = ({
  codeValue,
  handleCodeValue,
  inputPlaceholder,
  handleTelephoneValue,
  telephoneValue,
  inputAttrs,
}: TelephoneContactProps) => {
  return (
    <div className="telephone-contact">
      <CustomSelect
        value={codeValue}
        onChange={{ add: handleCodeValue }}
        options={countryCodeOptions}
        OptionComponent={CountryRow}
      />
      <input
        id="telephone"
        type="tel"
        pattern="^\d{6,}$"
        placeholder={inputPlaceholder}
        required
        minLength={2}
        maxLength={15}
        value={telephoneValue}
        onChange={handleTelephoneValue}
        {...inputAttrs}
      />
    </div>
  )
}

export default TelephoneContact
