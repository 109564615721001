import React, { Suspense, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCaptcha } from 'src/hooks/api'
import { InscribeForm, StatusForm } from 'src/server/types'

import './Captcha.sass'

interface CaptchaProps {
  inscribeForm: InscribeForm
  handleInscribeForm: (event: React.ChangeEvent<HTMLInputElement>) => void
  setInscribeForm: (value: React.SetStateAction<InscribeForm>) => void
  status: StatusForm
}

const Captcha = ({
  handleInscribeForm,
  inscribeForm,
  status,
  setInscribeForm,
}: CaptchaProps) => {
  const { number1, number2, token } = useCaptcha()
  const intl = useIntl()

  useEffect(() => {
    setInscribeForm((prev) => ({ ...prev, token }))
  }, [token, setInscribeForm])

  return (
    <>
      <div id="captcha">
        <strong>
          {inscribeForm.number1 || number1} + {inscribeForm.number2 || number2}{' '}
          =
        </strong>
        <input
          name="result"
          type="number"
          onChange={handleInscribeForm}
          value={inscribeForm.result}
          required
        />
      </div>
      <p className="captcha-text">
        <FormattedMessage
          id="inscribe.inscribe-form.step.captcha.text"
          values={{
            link: (
              <a
                target="_blank"
                href="https://www.adigital.org/politica-proteccion-datos-cookies-redes-sociales/"
                rel="noreferrer noopener"
                className="link"
              >
                {intl.formatMessage({
                  id: 'company.contact-form.check-text.privacy-policy',
                })}
              </a>
            ),
          }}
        />
      </p>
      <p className="captcha-text">
        <FormattedMessage
          id="inscribe.inscribe-form.step.captcha.bases"
          values={{
            link: (
              <a
                target="_blank"
                href="https://legal.adigital.org/digital-marketplace-bases-certificacion/"
                rel="noreferrer noopener"
                className="link"
              >
                {intl.formatMessage({
                  id: 'inscribe.inscribe-form.step.captcha.bases-link',
                })}
              </a>
            ),
          }}
        />
      </p>
      {status === StatusForm.ERROR_CAPTCHA && (
        <span className="error-inscribe-form">
          <FormattedMessage id="form.submit.error-captcha" />
        </span>
      )}
      {status === StatusForm.ERROR && (
        <span className="error-inscribe-form">
          <FormattedMessage id="form.submit.error" />
        </span>
      )}
      {status === StatusForm.SUCCESS && (
        <span className="success-inscribe-form">
          <FormattedMessage id="inscribe.inscribe-form.step.success" />
        </span>
      )}
    </>
  )
}

const CaptchaWrapper = (props) => (
  <Suspense>
    <Captcha {...props} />
  </Suspense>
)

export default CaptchaWrapper
