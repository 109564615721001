import React, {
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
  FocusEvent,
  useMemo,
  ReactNode,
} from 'react'

import './CustomSelect.sass'

enum KeyboardNavigation {
  ENTER = 'Enter',
  ARROWUP = 'ArrowUp',
  ARROWDOWN = 'ArrowDown',
  ESCAPE = 'Escape',
}

interface CustomSelectProps<T extends { value: string }> {
  value: string | Array<string>
  onChange: {
    add: (newValue: string) => void
    remove?: (valuteToRemove: string) => void
  }
  options: Array<T>
  OptionComponent: React.ElementType<{ option: T; checked?: boolean }>
  LabelComponent?: ReactNode
}

const CustomSelect = <T extends { value: string }>({
  onChange: { add, remove },
  value,
  options,
  OptionComponent,
  LabelComponent,
}: CustomSelectProps<T>) => {
  const [ddIndex, setDdIndex] = useState<number>(-1)
  const [searchValue, setSearchValue] = useState<string>('')
  const ulRef = useRef<HTMLUListElement>()
  const selectRef = useRef<HTMLDivElement>()

  const isMultiSelect = Array.isArray(value)

  //Put ddIndex when multi on [first,last] index of options?
  useEffect(() => {
    !isMultiSelect &&
      setDdIndex(options.findIndex((option) => option.value === value))
  }, [value, options, isMultiSelect])

  useEffect(() => {
    const timeout = setTimeout(() => setSearchValue(''), 1000)
    return () => clearTimeout(timeout)
  }, [searchValue])

  const actualOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value],
  )

  const blurActiveElement = (): void => {
    const activeElement = document.activeElement as HTMLElement
    activeElement.blur()
  }

  const handleChange = (item: string): void => {
    if (isMultiSelect) {
      value.includes(item) ? remove(item) : add(item)
    } else {
      add(item)
      blurActiveElement()
    }
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>): void => {
    event.preventDefault()
    const { code, key } = event

    if (
      !(Object.values(KeyboardNavigation) as Array<string>).includes(code) &&
      (code.startsWith('Key') || code.startsWith('Digit'))
    ) {
      setSearchValue((prevValue) => {
        const newValue = prevValue + key
        //Options should be sorted in alphabetical order
        const elementIndex = options.findIndex(
          (option, index) =>
            index > 0 && newValue.localeCompare(option.value) === -1,
        )
        if (elementIndex >= 0) {
          const element = ulRef.current.children[elementIndex] as HTMLElement
          element.focus()
          setDdIndex(elementIndex)
        }

        return newValue
      })
      return
    }

    if (code === KeyboardNavigation.ENTER) {
      handleChange(options[ddIndex].value)
      return
    }
    if (code === KeyboardNavigation.ESCAPE) {
      blurActiveElement()
      return
    }

    const offset = code === 'ArrowDown' ? ddIndex + 1 : ddIndex - 1
    if (offset >= options.length || offset < 0) return
    const element = ulRef.current.children[offset] as HTMLElement
    element.focus()
    setDdIndex(offset)
  }

  const handleBlur = (event: FocusEvent): void => {
    if (isMultiSelect) {
      setDdIndex(-1)
    } else if (
      ddIndex >= 0 &&
      !event.currentTarget.contains(event.relatedTarget)
    ) {
      handleChange(options[ddIndex].value)
    }
  }

  const handleOptionClick = () => !isMultiSelect && blurActiveElement()

  const handleHeaderBlur = (event: React.MouseEvent) => {
    if (selectRef.current.contains(document.activeElement)) {
      event.preventDefault()
      blurActiveElement()
    }
  }

  const handleOptionMouseDown = (index: number) => {
    setDdIndex(index)
    if (isMultiSelect) {
      handleChange(options[index].value)
    }
  }

  return (
    <div
      className="custom-select"
      data-testid="custom-select"
      tabIndex={0}
      ref={selectRef}
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
    >
      <div className="custom-select-header" onMouseDown={handleHeaderBlur}>
        <div className="custom-select-header-info">
          {LabelComponent
            ? LabelComponent
            : actualOption && <OptionComponent option={actualOption} />}
        </div>
        <span className="icon icon-down" />
      </div>
      <ul ref={ulRef} className="custom-select-list">
        {options.map((option, index) => (
          <li
            key={index}
            tabIndex={0}
            className="custom-select-row"
            onClick={handleOptionClick}
            onMouseDown={() => handleOptionMouseDown(index)}
          >
            <OptionComponent
              option={option}
              checked={isMultiSelect && value.includes(option.value)}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CustomSelect
