import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { createSlug } from 'src/utils/slug'

interface HiddenLinkProps {
  noLink: boolean
  bigSize?: boolean
  children: ReactNode
  name: string
  id: string
}

const HiddenLink = ({
  children,
  noLink,
  bigSize,
  id,
  name,
}: HiddenLinkProps) => {
  const url = `/company-posts/${createSlug({ id, name })}`
  if (noLink) {
    return (
      <span className={`company-info ${bigSize ? 'big-size' : ''}`}>
        {children}
      </span>
    )
  } else {
    return (
      <Link to={url} className={`company-info ${bigSize ? 'big-size' : ''}`}>
        {children}
      </Link>
    )
  }
}

export default HiddenLink
