import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import BannerAlt from 'src/routes/Cookies/BannerAlt'

import logoCert from 'src/static/images/logo_by_adigital.png'
import './Footer.sass'

const Footer = () => {
  const [showCookies, setShowCookies] = useState<boolean>(false)
  return (
    <footer id="page-footer">
      {showCookies && <BannerAlt closeBanner={() => setShowCookies(false)} />}
      <div className="container">
        <img src={logoCert} alt="certification logo" />
        <h2>
          <FormattedMessage id="footer.doubts" />
        </h2>
        <p className="contact">
          <FormattedMessage id="footer.contact" />
        </p>
        <a className="email" href="mailto:info@digitalmarketplace.es">
          info@digitalmarketplace.es
        </a>
        <ul className="items">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.adigital.org/aviso-legal/"
            >
              <FormattedMessage id="footer.legal-warning" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.adigital.org/politica-proteccion-datos-cookies-redes-sociales/"
            >
              <FormattedMessage id="footer.privacy-policy" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.adigital.org/politica-proteccion-datos-cookies-redes-sociales/#7"
            >
              <FormattedMessage id="footer.cookies-policy" />
            </a>
          </li>
          <li onClick={() => setShowCookies(true)}>
            <FormattedMessage id="footer.cookies" />
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://legal.adigital.org/digital-marketplace-bases-certificacion/"
            >
              <FormattedMessage id="footer.certification-base" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
