import { useCallback, useState } from 'react'
import { useKeyPress } from 'src/hooks/keyPress'
import { EntryAutocomplete } from 'src/server/types'

export const useNavigateArrows = (suggestions: Array<EntryAutocomplete>) => {
  const allItems = [
    ...(suggestions[0]?.items || []),
    ...(suggestions[1]?.items || []),
  ]
  useKeyPress(
    'ArrowUp',
    useCallback(() => {
      setHoveredItem((prevState) => (prevState > 0 ? prevState - 1 : prevState))
    }, []),
  )
  useKeyPress(
    'ArrowDown',
    useCallback(() => {
      setHoveredItem((prevState) =>
        prevState + 1 < allItems.length ? prevState + 1 : prevState,
      )
    }, [allItems.length]),
  )
  const [hoveredItem, setHoveredItem] = useState<number>(-1)

  const extractItem = () =>
    allItems.find((item, index) => (index === hoveredItem ? item : null))

  const resetHover = () => setHoveredItem(-1)

  const handleHover = (label: string) =>
    setHoveredItem(allItems.findIndex((item) => item.label === label))

  return {
    resetHover,
    extractItem,
    hoveredItem,
    handleHover,
  }
}
