import { useEffect } from 'react'

export const useKeyPress = (targetKey: string, update: () => void) => {
  const downHandler = ({ code }: KeyboardEvent) => {
    code === targetKey && update()
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  })
}
