import React, { Suspense, useMemo, useState } from 'react'
import { useCompanyTrack, usePosts } from 'src/hooks/api'
import { Link, Redirect, useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useModal } from '@gluedigital/modal'
import Carousel from '@gluedigital/react-carousel'
import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb'
import Tags from 'src/components/Tags/Tags'
import Loading from 'src/components/Loading/Loading'
import Footer from 'src/components/Footer/Footer'
import Navbar from 'src/components/Navbar/Navbar'
import ContactForm from './ContactForm/ContactForm'
import CompanyVideo from './CompanyVideo/CompanyVideo'
import PostCards from 'src/components/PostCards/PostCards'
import CompanyInfo from 'src/components/CompanyInfo/CompanyInfo'
import { marked } from 'marked'
import { createSlug } from 'src/utils/slug'
import SlideHandler from 'src/components/Slides/SlideHandler'
import Slides from 'src/components/Slides/Slides'

import './ContactModal/ContactModal'
import './Company.sass'

declare const __CLIENT__: boolean

const Company = () => {
  const { id, slug } = useParams<{ id: string; slug?: string }>()
  const company = useCompanyTrack(id)

  const {
    categories,
    description,
    employees,
    logo_img,
    name,
    tags,
    videos,
    services,
    header_img,
    id: companyId,
    date_modified,
    header_img_mobile,
  } = company

  const posts = usePosts()
  const postsFiltered = useMemo(
    () => posts.filter(({ account_id }) => account_id === companyId),
    [posts, companyId],
  )

  const modal = useModal()

  const [openModal, setOpenModal] = useState(false)

  const canonical = createSlug(company)
  if (`${id}/${slug}` !== canonical) {
    return <Redirect to={`/company/${canonical}`} />
  }

  const handleModal = () =>
    modal.show('contact-modal', { handleChangeModal, companyId: id })

  const handleChangeModal = () => setOpenModal((prev) => !prev)

  return (
    <div id="company" className="page">
      <Navbar logoColor={true} />
      <div className="container">
        <Breadcrumb />
      </div>
      <header id="header-company" className="container">
        <div className="header-company-wrapper">
          <picture style={{ objectFit: 'cover' }}>
            <source srcSet={header_img} media="(min-width: 768px)" />
            <source srcSet={header_img_mobile} />
            <img src={header_img} alt="Company Logo" />
          </picture>
          <CompanyInfo
            bigSize
            image={logo_img}
            name={name}
            id={companyId}
            noLink
          />
        </div>
      </header>
      <div id="separator" className="container">
        <div id="principal">
          <section className="mobile categories">
            <p className="category-name">
              {categories.map((category) => category.category_name).join(', ')}
            </p>
            <Tags tags={tags.map((tag) => tag.tag_name)} maxTags={5} />
          </section>
          <section className="only-desktop categories">
            {categories.map((category) => (
              <div key={category.category_id}>
                <Link
                  to={`/results?query=${encodeURIComponent(
                    category.category_name,
                  )}`}
                >
                  <span className="category-name">
                    {category.category_name}
                  </span>
                </Link>
                <Tags
                  tags={category.tags.map((tag) => tag.tag_name)}
                  maxTags={3}
                />
              </div>
            ))}
          </section>
          <section className="video-container scroll">
            {__CLIENT__ && !!videos.length && (
              <Carousel loop align="start" dots={<SlideHandler />}>
                <Slides
                  slides={videos.map((video) => (
                    <div key={video.title}>
                      <CompanyVideo video={video} />
                    </div>
                  ))}
                ></Slides>
              </Carousel>
            )}
          </section>
          <section id="services">
            <h1 className="title">
              <FormattedMessage id="company.services.title" />
            </h1>
            <div
              id="company-services"
              dangerouslySetInnerHTML={{ __html: marked.parse(services) }}
            ></div>
          </section>
          <button
            className={`contact-button ${openModal ? 'opened' : ''}`}
            onClick={() => {
              handleChangeModal()
              handleModal()
            }}
          >
            <FormattedMessage id="company.button-contact" />
          </button>
          <section id="contact">
            <CompanyInfo
              bigSize
              image={logo_img}
              name={name}
              id={companyId}
              noLink
            />
          </section>
          <section className="table-info">
            <div className="date table-item top">
              <span className="table-item-gray">
                <FormattedMessage id="company.table.member-since" />
              </span>
              <span
                className="table-item-content"
                id="company-date-modified"
              >{`${new Date(date_modified).toLocaleString('es-ES', {
                month: 'long',
              })}, ${new Date(date_modified).toLocaleString('es-ES', {
                year: 'numeric',
              })}`}</span>
            </div>
            <div className="employes table-item top">
              <span className="table-item-gray">
                <FormattedMessage id="company.table.number-employes" />
              </span>
              <span className="table-item-content">{employees}</span>
            </div>
            <div className="description table-item">
              <span className="table-item-gray">
                <FormattedMessage id="company.table.description" />
              </span>
              <p
                className="table-item-content-p"
                dangerouslySetInnerHTML={{ __html: marked.parse(description) }}
              ></p>
            </div>
          </section>
          {!!postsFiltered.length && (
            <section id="posts">
              <div className="posts-head">
                <h4>
                  <FormattedMessage id="company.posts.title" />
                </h4>
                <Link
                  to={`/company-posts/${createSlug(company)}`}
                  className="show-post"
                >
                  <FormattedMessage
                    id="company.posts.see-all"
                    values={{ company: <strong>{name}</strong> }}
                  />
                </Link>
              </div>
              <div className="post-container scroll">
                <PostCards posts={postsFiltered} numberOfItems={3} />
              </div>
            </section>
          )}
        </div>
        <section id="form-contact">
          <ContactForm companyId={id} />
        </section>
      </div>
      <Footer />
    </div>
  )
}

const CompanyWrapper = () => (
  <Suspense fallback={<Loading />}>
    <Company />
  </Suspense>
)

export default CompanyWrapper
