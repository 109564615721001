import React from 'react'
import { FormattedMessage } from 'react-intl'
import { InscribeForm, StatusForm } from 'src/server/types'

import './FooterForm.sass'

interface FooterFormProps {
  inscribeForm: InscribeForm
  stepsAmount: number
  goPreviousStep: () => void
  status: StatusForm
}

const FooterForm = ({
  inscribeForm,
  stepsAmount,
  goPreviousStep,
  status,
}: FooterFormProps) => {
  return (
    <footer id="footer-form">
      <button
        type="button"
        className="back"
        onClick={goPreviousStep}
        disabled={inscribeForm.step <= 0}
        name={`step-${inscribeForm.step - 1}`}
      >
        <span>
          <FormattedMessage id="inscribe.inscribe-form.button.back" />
        </span>
      </button>
      <button
        type="submit"
        disabled={
          status === StatusForm.LOADING || status === StatusForm.SUCCESS
        }
        name={`step-${inscribeForm.step + 1}`}
      >
        {stepsAmount - 1 === inscribeForm.step ? (
          status === StatusForm.LOADING ? (
            <span>
              <FormattedMessage id="form.submit.button-label-loading" />
            </span>
          ) : (
            <span>
              <FormattedMessage id="inscribe.inscribe-form.button.send" />
            </span>
          )
        ) : (
          <span>
            <FormattedMessage id="inscribe.inscribe-form.button.next" />
          </span>
        )}
      </button>
    </footer>
  )
}

export default FooterForm
